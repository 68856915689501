import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  Input,
  EventEmitter,
  Output,
} from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";

import * as moment from "moment";
import { environment } from "src/environments/environment";

declare let $: any;
declare let window: any;
declare let html2pdf: any;
declare let Jhxlsx: any;

@Component({
  selector: "billing-monthly",
  templateUrl: "billing.component.html",
  styleUrls: ["./billing.component.css"],
})
export class BillingComponent implements OnInit, OnDestroy {
  @Input("mid") mid: any;
  @Input("lid") lid: any;
  @Input("monthYear") monthYear: any;
  @Output("close") hideModel: any = new EventEmitter<boolean>();

  serviceNames: any = {
    admin: {
      "AWS Elastic Compute Cloud": "Elastic Compute Cloud",
      "AWS Relational Database Service": "Relational Database Service",
      "AWS Cloudfront": "Cloudfront",
      AWSCloudfront: "Cloudfront",
      "Amazon CloudFront": "CloudFront",
      "Amazon Simple Storage Service": "Simple Storage Service",
      AmazonCloudFront: "CloudFront",
      AmazonCloudront: "Cloudfront",
      "Amazon Relational Database Service": "Relational Database Service",
      "Amazon Elastic Compute Cloud": "Elastic Compute Cloud",
    },
    client: {
      "AWS Elastic Compute Cloud": "AWS Elastic Compute Cloud",
      "AWS Relational Database Service": "AWS Relational Database Service",
      "AWS Cloudfront": "AWS Cloudfront",
      AWSCloudfront: "AWSCloudfront",
      "Amazon CloudFront": "Amazon CloudFront",
      "Amazon Simple Storage Service": "Simple Storage Service",
      AmazonCloudFront: "AmazonCloudFront",
      AmazonCloudfront: "AmazonCloudfront",
      "Amazon Relational Database Service":
        "Amazon Relational Database Service",
      "Amazon Elastic Compute Cloud": "Amazon Elastic Compute Cloud",
    },
  };

  government_client : any = 440591205624;
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  customerType: string = localStorage.getItem("customerType");
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  curMissing: boolean = false; // TODO: Change value to true before deployments
  summary: any = [];
  analytics: any;
  activeAccount: any = null;
  diffDays: number = 30;
  dateRange: string;
  billingOf: string = "ec2";
  servicesData: any = {};
  government_data:any =[];
  filterText = "";

  government_headers: any = [

      {
        id: "productName",
        name: "Service",
        export: true,
        sort: { sort: true, direction: "Asc" },
        filter: true,
      },
      {
        id: "operation",
        name: "Sub-Service type",
        export: true,
        sort: { sort: true, direction: "Asc" },
        filter: true,
      },
      {
        id: "region",
        name: "Region",
        export: true,
        sort: { sort: true, direction: "Asc" },
        filter: true,
      },
      {
        id: "uom",
        name: "UoM",
        export: true,
        sort: { sort: true, direction: "Asc" },
        filter: true,
      },
      {
        id: "description",
        name: "Unit Price - details",
        export: true,
        sort: { sort: true, direction: "Asc" },
        filter: true,
      },
      {
        id: "unitPrice",
        name: "Unit Price (USD)",
        export: true,
        sort: { sort: true, direction: "Asc" },
      },
      {
        id: "usageAmount",
        name: "Qty/Request",
        export: true,
        sort: { sort: true, direction: "Asc" },
      },
      {
        id: "unblendedCost",
        name: "Individual Price (USD)",
        export: true,
        sort: { sort: true, direction: "Asc" },
      },
      // {
      //   id: "serviceCost",
      //   name: "Price Per Service (USD)",
      //   export: true,
      //   sort: { sort: true, direction: "Asc" },

      // },
      
  ]

  ec2Headers: any = [
    {
      id: "masterAccountId",
      name: "Master account ID",
      export: true,
    },
    {
      id: "lid_instanceId",
      name: "Instance ID",
      export: true,
    },
    {
      id: "instance_name",
      name: "Tag Name",
      export: true,
    },
    {
      id: "projectTagName",
      name: "Project Tag Name",
      export: true,
      displayConditional: true,
    },
    {
      id: "mapMigrated",
      name: "Map Migrated Tag Name",
      export: true,
      displayConditional: true,
    },
    {
      id: "admin_instance_type",
      name: "Instance Type",
      export: true,
    },
    {
      id: "cpu",
      name: "CPU",
      export: true,
    },
    {
      id: "ram",
      name: "Ram",
      export: true,
    },
    {
      id: "os",
      name: "OS",
      export: true,
    },
    {
      id: "region",
      name: "Region",
      export: true,
    },
    {
      id: "RI",
      name: "RI Applied",
      export: true,
    },
    {
      id: "billingType",
      name: "Billing Type",
      export: true,
      alter: (value: any, param: any) => {
        let billingType: string = "";
        if (value[param] == "Dis-RI") {
          billingType = "OD-Discount";
        } else if (value[param] == "M-RI") {
          billingType = "M-Reserved";
        } else if (value[param] == "Customer RI") {
          billingType = "Cust-Reserved";
        } else {
          billingType = value[param];
        }
        return billingType;
      },
    },
    {
      id: "hourly_cost",
      name: "AWS Cost/Hr",
      export: false,
      alter: (value: any, param: string) => {
        if (value[param] == "null" || value["billingType"] == "Dis-RI") {
          return "-";
        }
        if (value["RI"] && value["RI"].toLowerCase() == "yes") {
          if (value["billingType"] != "unUsedCustomerRI") {
            if (value["billingType"] == "Customer RI(Unused RI)") {
              return `<span>${Number(value["riHourlyCost"]).toFixed(4)}</span>`;
            } else {
              return `<span class='line_through'>${Number(value[param]).toFixed(
                4
              )}</span> <span>${Number(value["riHourlyCost"]).toFixed(
                4
              )}</span>`;
            }
          } else {
            return Number(value["riHourlyCost"].toFixed(4));
          }
        } else {
          return Number(value[param]).toFixed(4);
        }
      },
    },
    {
      id: "hourly_cost",
      name: "AWS Cost/Hr",
      export: true,
      hide: true,
      alter: (value: any, param: string) => {
        if (value[param] == "null" || value["billingType"] == "Dis-RI") {
          return "-";
        }
        if (value["RI"] && value["RI"].toLowerCase() == "yes") {
          if (value["billingType"] != "unUsedCustomerRI") {
            return `<span>${Number(value[param]).toFixed(4)}</span>`;
          } else {
            return Number(value["riHourlyCost"].toFixed(4));
          }
        } else {
          return Number(value[param]).toFixed(4);
        }
      },
    },
    {
      id: "total_hours",
      name: "AWS Total Hours",
      export: true,
      alter: (value: any, param: string) => {
        return isNaN(value[param]) ? "-" : Number(value[param]).toFixed(2);
      },
    },
    {
      id: "total_cost",
      name: "AWS Total Cost",
      export: false,
      alter: (value: any, param: string) => {
        if (isNaN(value[param])) {
          return "-";
        } else if (value["RI"] && value["RI"].toLowerCase() == "yes") {
          if (value["billingType"] != "unUsedCustomerRI") {
            if (value["billingType"] == "Customer RI(Unused RI)") {
              return `<span>${Number(value["riTotalCost"]).toFixed(4)}</span>`;
            } else {
              return `<span class='line_through'>${Number(value[param]).toFixed(
                4
              )}</span> <span>${Number(value["riTotalCost"]).toFixed(
                4
              )}</span>`;
            }
          } else {
            return Number(value["riTotalCost"].toFixed(4));
          }
        } else {
          return Number(value[param]).toFixed(4);
        }
      },
    },
    {
      id: "total_cost",
      name: "AWS Total Cost",
      hide: true,
      export: true,
      alter: (value: any, param: string) => {
        return isNaN(value[param]) ? "-" : Number(value[param]).toFixed(4);
      },
    },
    {
      id: "riHourlyCost",
      name: "RI Rate",
      export: true,
      hide: true,
      alter: (value: any, param: string) => {
        return isNaN(value[param]) ? "-" : Number(value[param]).toFixed(4);
      },
    },
    {
      id: "riTotalCost",
      name: "Final Total Cost",
      hide: true,
      export: true,
      alter: (value: any, param: string) => {
        return value["RI"] && value["RI"].toLowerCase() == "yes"
          ? value["billingType"] == "unUsedCustomerRI"
            ? `${Number(value["total_cost"]).toFixed(4)}`
            : Number(value[param]).toFixed(4)
          : Number(value["total_cost"]).toFixed(4);
      },
    },
  ];
  ebsHeaders: any = [
    {
      id: "lid_volumeId",
      name: "Volume ID",
      export: true,
    },
    {
      id: "volume_name",
      name: "Tag Name",
      export: true,
    },
    {
      id: "projectTagNameEBS",
      name: "Project Tag Name",
      export: true,
      displayConditional: true,
    },
    {
      id: "mapMigratedEBS",
      name: "Map Migrated Tag Name",
      export: true,
      displayConditional: true,
    },
    {
      id: "volume_type",
      name: "Volume Type",
      export: true,
    },
    {
      id: "gb_cost",
      name: "GB Cost",
      export: true,
    },
    {
      id: "total_storage",
      name: "Total Storage",
      export: true,
    },
    {
      id: "total_cost",
      name: "Total Cost",
      export: true,
    },
  ];
  othersHeaders: any = [
    {
      id: "lid_resourceId",
      name: "Resource ID",
      export: true,
    },
    {
      id: "resource_name",
      name: "Resource Name",
      export: true,
    },
    {
      id: "total_Gb_or_Cost",
      name: "Total GB",
      export: true,
      alter: (value: any) => {
        return Number(value).toFixed(2);
      },
    },
    {
      id: "total_cost",
      name: "Total GB Cost",
      export: true,
      alter: (value: any) => {
        return Number(value).toFixed(2);
      },
    },
  ];

  projectTagNameEC2: boolean = false;
  mapMigratedEC2: boolean = false;
  projectTagNameEBS: boolean = false;
  mapMigratedEBS: boolean = false;
  downloadAll: boolean = false;

  funRef: any;

  summaryHeader: any = [
    {
      id: "lid",
      name: "Linked Account Number",
      show: true,
      sort: { sort: true, direction: "Asc" },
      type: "String",
      filter: true,
      style: { width: 0 },
      click: "showData",
    },
    {
      id: "accname",
      name: "Account Name",
    },
    {
      id: "scount",
      name: "Service Count",
    },
    {
      id: "credits",
      name: "Credits",
    },
    {
      id: "totalcost",
      name: "Cost(appr.)",
    },
  ];

  latestDataSummaryheader: any = [
    {
      id: "lid",
      name: "Linked Account Number",
      show: true,
      sort: { sort: true, direction: "Asc" },
      type: "String",
      filter: true,
      style: { width: 0 },
      click: "showData",
    },
  ];

  billsHeader: any = [
    {
      id: "service-name",
      name: "Service Name",
      clickCheck: (id: any) => {
        id = this.serviceNames[this.urlPrefix][id];
        return id ? true : false;
      },
      click: "loadInnerData",
    },
    { id: "html", name: "Service Cost" },
  ];

  accountId: any;
  regionId: any;
  currentMessage: any;
  loaded: boolean = false;

  masterAccounts: any = [];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.funRef = {
      showData: this.showData,
      loadInnerData: this.loadInnerData,
    };

    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    if (this.customerType !== "mp") {
      $("#masterAccountSelect").selectpicker();
    }
    $(".datetimepicker")
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: "mm-yyyy",
        orientation: "bottom auto",
        endDate: new Date(),
      })
      .on("changeDate", function (selected: any) {
        $(".datetimepicker").val(
          moment(selected["dates"][0]).format("MM-YYYY")
        );
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });

    $(".datetimepicker").val(
      this.monthYear || moment().startOf("month").format("MM-YYYY")
    );

    this.monthYear = null;

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      this.masterAccounts = [];
      if (d.key == "accountId") {
        console.log('accounts switch');
        this.accountId = d.value;
        if (this.loaded && this.lid && this.accountId != this.lid) {
          this.hideModel.emit(false);
        }
        this.loaded = true;
        this.masterAccounts = [];
        this.summary = [];
        this.government_data = [];
        this.response[this.lid || this.accountId] = null;
        setTimeout(() => {
          if (this.customerType !== "mp") {
            $("#masterAccountSelect").selectpicker("refresh");
          }
          this.load();
        }, 100);
      } else if (d.key == "regionId") {
        console.log('region switch');
        this.regionId = d.value;
        this.masterAccounts = [];
        this.summary = [];
        this.government_data = [];
        this.response[this.lid || this.accountId] = null;
        setTimeout(() => {
          if (this.customerType !== "mp") {
            $("#masterAccountSelect").selectpicker("refresh");
          }
          this.load();
        }, 100);
      }
    });

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: () => {
        setTimeout(() => {
          this.load();
        }, 100);
      },
      component: this,
    };
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async checkForExistingClient() {
    let data: any = { a: "validate", accountId: this.accountId };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/billinguser`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      if (result.existingCustomer) {
        this.customerType = "C";
      } else {
        this.customerType = "mp";
      }

      if (!result.curStatus || !result.s3Status) {
        this.curMissing = true;
      }
    }
  }

  async load() {
    this.customerType = localStorage.getItem("customerType");
    this.notifier.loading(true);
    if (this.customerType == "mp") {
      await this.checkForExistingClient();
    }
    this.analytics = null;
    await this.fetchDiffDays();
    // this.notifier.loading(false);
  }

  startOf: any;
  endOf: any;

  async fetchDiffDays() {
    console.log("fetchDiffDays");

    let currentDate: any =
      $(".datetimepicker").val() || moment().format("MM-YYYY");
    let liveDate: any = moment(`02-2022`, "MM-YYYY");
    this.startOf = moment(currentDate, "MM-YYYY");
    this.endOf = moment(currentDate, "MM-YYYY");
    let currentMonth = false;
    if (
      (new Date().getTime() -
        new Date(
          moment(currentDate, "MM-YYYY")
            .subtract(1, "months")
            .endOf("month")
            .format("MM-DD-YYYY")
        ).getTime()) /
        (1000 * 3600 * 25) <
      moment(currentDate, "MM-YYYY")
        .subtract(1, "months")
        .endOf("month")
        .daysInMonth()
    ) {
      currentMonth = true;
    }
    this.startOf = this.startOf.startOf("day");
    this.startOf = this.startOf.format("DD-MM-YYYY");
    if (!currentMonth) {
      this.endOf = this.endOf.endOf("month");
      this.endOf = this.endOf.format("DD-MM-YYYY");
    } else {
      this.endOf = moment(
        `${new Date().getDate()}-${
          new Date().getMonth() + 1
        }-${new Date().getFullYear()}`,
        "D-M-YYYY"
      ).format("DD-MM-YYYY");
    }
    currentDate = moment(currentDate, "MM-YYYY");
    currentDate = currentDate.endOf("month").valueOf();
    currentDate = new Date(currentDate);
    liveDate = liveDate.endOf("month").valueOf();
    liveDate = new Date(liveDate);
    let diff =
      (currentDate.getTime() - liveDate.getTime()) / (1000 * 3600 * 24);

    this.diffDays = diff;
    setTimeout(async () => {
      if (this.diffDays > 0) {
        await this.fetchMasterAccounts();
      } else {
        // if(this.government_client !== this.masterAccounts){
          await this.loadSummaryData();
        // }
        if(this.government_client == this.masterAccounts){
          await this.governmentData(null);
        }
      }
    }, 100);
  }

  async loadInnerData(ref: any, id: any) {
    await ref.loadRegions();
  }

  download: string = "";

  async exportPDFTrigger(event: any) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.notifier.loading(true);
    this.download = "pdf";
    let lids = [];
    if (this.downloadAll) {
      let accounts = JSON.parse(localStorage.getItem("accountIdData"));
      lids = accounts.list.map((account: any) => {
        return account["accountId"];
      });
    } else {
      lids = [this.accountId];
    }

    let promises: any = [];
    lids.forEach(async (account: any) => {
      if (this.government_client === this.masterAccounts) {
        promises.push(
          this.governmentData(null)
        );
      }
    
      // if (this.government_client !== this.masterAccounts) {
        console.log('export pdf');
        promises.push(
          this.loadSummaryData($("#masterAccountSelect").val(), account)
        );
      // }
    });
    

    Promise.all(promises).then((values: any) => {
      this.download = null;
      this.notifier.loading(false);
    });
  }

  exportPDF(event?: any, account: any = this.accountId || this.lid) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (
      !this.response[account] ||
      this.response[account]["billDetails"]["items"][0]["services"].length == 0
    ) {
      return;
    }

    let element = document.createElement("div");
    let ec2Index = 0;
    let total = this.getPDFTotal(account);
    var ondemandCost = parseFloat(
      this.response[account].onDemandFinalTotalCost
    );
    var spotCost = parseFloat(this.response[account].spotFinalTotalCost);
    var mRiCost = parseFloat(this.response[account].mRiTotalCost);
    var finalimriCost = parseFloat(this.response[account].mRiFinalTotalCost);
    var custRiCost = parseFloat(this.response[account].customerRITotalCost);
    var finalCustRiCost = parseFloat(
      this.response[account].customerRIFinalTotalCost
    );
    var ondemadisCost = parseFloat(this.response[account].disRiTotalCost);
    var finalDisCost = parseFloat(this.response[account].disRiFinalTotalCost);
    var minfyRiCost = parseFloat(this.response[account].mRiTotalCost);
    var finalMinfyRiCost = parseFloat(
      this.response[account].minfyRiFinalTotalCost
    );
    var ebsCost = parseFloat(this.response[account].ebsTotalCost);
    var othersCost = parseFloat(this.response[account].othersTotalCost);
    var totalec2Cost =
      ondemandCost +
      spotCost +
      finalMinfyRiCost +
      finalimriCost +
      finalCustRiCost +
      finalDisCost;

    element.innerHTML = `
      <div
        id="completebillDetailsTable"
        class="container"
        style="
          margin-bottom: 100px;
          font-weight: 400;
          padding: 0px 25px;
          color: #000000;
        "
      >
        <div>
          <div class="row">
            <div class="col-sm-5">
              <div style="margin: auto">
                <img
                  style="margin-top: 80px"
                  src="../assets/img/minfy_logo.png"
                  alt="company logo"
                  width="75%"
                />
              </div>
            </div>
            <div class="col-sm-7" style="display: flex; flex-direction: column">
              <div style="padding-bottom: 5px; border-bottom: 3px solid gainsboro">
                <p style="font-weight: 780; font-family: Arial; font-size: 15px">
                  AWS Usage Report
                </p>
                <p style="font-family: Arial; font-size: 12px">
                  For any billing related queries. email us at
                  <span style="color: blue"> billing-support@minfytech.com </span>
                </p>
              </div>
              <div style="padding-bottom: 5px; border-bottom: 3px solid gainsboro">
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                  "
                >
                  <p style="font-family: Arial; font-size: 12px">
                    AWS Service Charges
                  </p>
                  <p style="font-family: Arial; font-size: 12px">
                    $<span id="awsodCost" class='pdf_black' style='color: black;'>${this.getTotalRAW(
                      account
                    )}</span>
                  </p>
                </div>
              </div>
              <div style="padding-bottom: 5px; border-bottom: 3px solid gainsboro">
                <div
                  class="highlights"
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    font-family: Arial;
                    font-weight: bold;
                    font-size: 15.5px;
                  "
                >
                  <p>TOTAL AMOUNT <span style='font-size: 0.8em; color: inherit;'>(Excluding tax)</span></p>
                  <p>$<span id="awsTotalCost" style='color: black;'>${this.getDiscountedTotalRAW(
                    account
                  )}</span></p>
                </div>
              </div>
            </div>

            <div class="col-sm-12" style="font-family: Arial; font-size: 12px">
              <!-- <p ><span class="highlights">AWS Usage Report Date: </span><span id="reportDate"></span></p> -->

              <p>
                <span class="highlights pdf_black" style='font-weight: bold;'>Customer: </span
                ><span id="customerName" class='pdf_black' style='color: black;'>${account}</span>
              </p>

              <p class="highlights" style='font-weight: bold;'>
                This AWS Usage Report is for the billing period
                <span id="billingPeriod" class='pdf_black' style='color: black;'>${
                  this.startOf
                } - ${this.endOf}</span>
              </p>
              <p style="line-height: 1.5; margin-bottom: 5px; font-size: 11px">
                Greetings from MINFY, we're writing to provide you with an electronic
                report for your use of AWS services. Additional information about your
                bill,individual service charge details, and your account history are
                available on Swayam Portal.
              </p>
            </div>

            <table
              class="table"
              id="completepdfTable"
              style="
                border: 1.5px solid #d1d1a1;
                width: 720px;
                float: left;
                font-family: Arial;
                font-size: 12px;
                margin: 10px;
                color: #000000;
              "
            >
              <thead>
                <tr style="border-bottom: 1px solid #d1d1a1">
                  <th
                    style="text-align: left; background-color: #c8e1f5"
                    class="highlights"
                  >
                    Service Breakup Summary
                  </th>

                  <th style="background-color: #c8e1f5"></th>
                </tr>
              </thead>
              <tbody
                id="completepdfTableBody"
                style="font-family: Arial; font-size: 12px"
              >
                <tr style="border-bottom: 1px solid #d1d1a1" class="highlights">
                  <td style="color: #fcb65c">AWS Service Charges</td>
                  <td style="text-align: right">
                    $ <span id="totalOdCost" style='color: #000;'><b style='color: black;'>${this.getDiscountedTotalRAW(
                      account
                    )}</b></span>
                  </td>
                </tr>

                ${this.response[account]["billDetails"]["items"][0]["services"]
                  .map((service: any, index: any) => {
                    let service_name =
                      service["service-name"] || service.service_nam;
                    let service_cost = Number(service["service-cost"]).toFixed(
                      2
                    );
                    let servicename = null;
                    if (
                      service_name ===
                        this.serviceNames[this.urlPrefix][
                          "Amazon CloudFront"
                        ] &&
                      this.servicesData[account]["cloudfront"]["discountedCost"]
                    ) {
                      servicename = "cloudfront";
                    }
                    if (
                      service_name ===
                        this.serviceNames[this.urlPrefix][
                          "Amazon Simple Storage Service"
                        ] &&
                      this.servicesData[account]["s3"]["discountedCost"]
                    ) {
                      servicename = "s3";
                    }
                    if (
                      service_name ===
                        this.serviceNames[this.urlPrefix][
                          "Amazon Elastic Compute Cloud"
                        ] &&
                      this.servicesData[account]["ec2"]["discountedCost"]
                    ) {
                      servicename = "ec2";
                    }
                    if (
                      service_name ===
                        this.serviceNames[this.urlPrefix][
                          "Amazon Relational Database Service"
                        ] &&
                      this.servicesData[account]["rds"]["discountedCost"]
                    ) {
                      servicename = "rds";
                    }
                    if (
                      service_name ==
                      this.serviceNames[this.urlPrefix][
                        "Amazon Elastic Compute Cloud"
                      ]
                    ) {
                      ec2Index = index;
                      return "";
                    }
                    let service_html = service["html"];
                    let element = document.createElement("span");
                    element.innerHTML = service_html;
                    element.querySelectorAll("span").forEach((span: any) => {
                      span.style.color = "black";
                      if (span.querySelectorAll("span").length == 0) {
                        span.innerHTML = isNaN(span.innerText)
                          ? span.innerText
                          : Number(span.innerText).toFixed(2);
                      }
                    });
                    return `
                <tr class="service">
                  <td style="padding-left: 50px">${service_name}</td>
                    ${
                      servicename
                        ? `
                      <td style="text-align: right">
                      ${
                        this.servicesData[account][servicename][
                          "discountedCost"
                        ]
                          ? this.servicesData[account][servicename][
                              "discountedCost"
                            ] <
                            this.servicesData[account][servicename][
                              "service-cost"
                            ]
                            ? ` 
                        $<span><span class="line_through">
                            ${
                              this.servicesData[account][servicename][
                                "service-cost"
                              ] || 0
                            }</span> $<span class="bold_text">
                            ${
                              this.servicesData[account][servicename][
                                "discountedCost"
                              ] || 0
                            }</span></span>
                      `
                            : `
                            <span class="line_through">
                            ${
                              this.servicesData[account][servicename][
                                "discountedCost"
                              ] || 0
                            }</span> $<span class="bold_text">
                            ${
                              this.servicesData[account][servicename][
                                "service-cost"
                              ] || 0
                            }</span></span>
                      `
                          : `$${service_cost || 0}`
                      }
                    </td>
                    `
                        : `<td style='text-align: right;'><span style='color: black; text-align: right;'>$${element.innerHTML}</span></td>`
                    }
                </tr>
                `;
                  })
                  .join("")}

                  ${
                    this.servicesData[account]["ec2"]["service-cost"] > 0
                      ? `
                    <tr class="service">
                      <td style="padding-left: 50px">
                        ${
                          this.response[account]["billDetails"]["items"][0][
                            "services"
                          ][ec2Index]["service-name"] ||
                          this.response[account]["billDetails"]["items"][0][
                            "services"
                          ][ec2Index]["service_name"]
                        }
                      </td>
                      <td style="text-align: right">
                      ${
                        this.servicesData[account]["ec2"]["discountedCost"]
                          ? this.servicesData[account]["ec2"][
                              "discountedCost"
                            ] <
                            this.servicesData[account]["ec2"]["service-cost"]
                            ? ` 
                        $<span class="line_through">
                            ${
                              this.servicesData[account]["ec2"][
                                "service-cost"
                              ] || 0
                            }</span> $<span class="bold_text">
                            ${
                              this.servicesData[account]["ec2"][
                                "discountedCost"
                              ] || 0
                            }</span>
                      `
                            : `
                            $<span class="line_through">
                            ${
                              this.servicesData[account]["ec2"][
                                "discountedCost"
                              ] || 0
                            }</span> $<span class="bold_text">
                            ${
                              this.servicesData[account]["ec2"][
                                "service-cost"
                              ] || 0
                            }</span></span>
                      `
                          : `$${
                              this.servicesData[account]["ec2"][
                                "service-cost"
                              ] || 0
                            }`
                      }
                    
                  `
                      : ""
                  } 
                  </td>
                    </tr>
                  ${
                    ondemandCost > 0
                      ? `
                    <tr class="service">
                      <td style="padding-left:100px">
                      Ondemand 
                      </td>
                      <td style="text-align:right" >$
                      ${ondemandCost.toFixed(2)}
                      </td>	
                      </tr>
                  `
                      : ""
                  }
                  ${
                    spotCost > 0
                      ? `
                    <tr class="service">
                    <td style="padding-left:100px">
                    Spot 
                    </td>
                    <td style="text-align:right" >$
                    ${spotCost.toFixed(2)}
                    </td>	
                    </tr>
                  `
                      : ""
                  }

                  ${
                    mRiCost > 0 && finalimriCost > 0
                      ? mRiCost == finalimriCost
                        ? `
                    <tr class="service">
                      <td style="padding-left:100px">
                      M-Reserved 
                      </td>
                      <td style="text-align:right" >$
                      ${mRiCost.toFixed(2)}
                      </td>	
                      </tr>
                  `
                        : `
                    <tr class="service">
                    <td style="padding-left:100px">
                    M-Reserved 
                    </td>
                    <td style="text-align:right" >$
                    <span style="text-decoration: line-through;">${mRiCost.toFixed(
                      2
                    )}</span>  $${finalimriCost.toFixed(2)}
                    </td>	
                    </tr>
                  `
                      : ""
                  }

                  ${
                    custRiCost > 0 && finalCustRiCost > 0
                      ? custRiCost == finalCustRiCost
                        ? `
                    <tr class="service">
                      <td style="padding-left:100px">
                      Cust-Reserved
                      </td>
                      <td style="text-align:right" >$
                      ${custRiCost.toFixed(2)}
                      </td>	
                      </tr>
                  `
                        : `
                    <tr class="service">
                    <td style="padding-left:100px">
                    Cust-Reserved
                    </td>
                    <td style="text-align:right" >$
                    <span style="text-decoration: line-through;">${custRiCost.toFixed(
                      2
                    )}</span>  $${finalCustRiCost.toFixed(2)}
                    </td>	
                    </tr>
                  `
                      : ""
                  }

                  ${
                    ondemadisCost > 0 && finalDisCost > 0
                      ? ondemadisCost == finalDisCost
                        ? `
                        <tr class="service">
                        <td style="padding-left:100px">
                        Ondemand Cost 
                        </td>
                        <td style="text-align:right" >$
                        ${ondemadisCost.toFixed(2)}
                        </td>	
                        </tr>
                  `
                        : `
                    <tr class="service">
                    <td style="padding-left:100px">
                    Ondemand Cost
                    </td>
                    <td style="text-align:right" >$
                    <span style="text-decoration: line-through;">${ondemadisCost.toFixed(
                      2
                    )}</span>  $${finalDisCost.toFixed(2)}
                    </td>	
                    </tr>
                  `
                      : ""
                  }

                  ${
                    minfyRiCost > 0 && finalMinfyRiCost > 0
                      ? minfyRiCost == finalMinfyRiCost
                        ? `
                        <tr class="service">
                        <td style="padding-left:100px">
                        Minfy RI 
                        </td>
                        <td style="text-align:right" >$
                        ${minfyRiCost.toFixed(2)}
                        </td>	
                        </tr>
                  `
                        : `
                        <tr class="service">
                        <td style="padding-left:100px">
                        Minfy RI
                        </td>
                        <td style="text-align:right" >$
                        <span style="text-decoration: line-through;">${minfyRiCost.toFixed(
                          2
                        )}</span>  $${finalMinfyRiCost.toFixed(2)}
                        </td>	
                        </tr>
                  `
                      : ""
                  }

                  ${
                    ebsCost > 0
                      ? `
                    <tr class="service">
                    <td style="padding-left:75px">
                    EBS 
                    </td>
                    <td style="text-align:right" >$
                    ${ebsCost.toFixed(2)}
                    </td>	
                    </tr>
                  `
                      : ""
                  } 
                  ${
                    othersCost > 0
                      ? `
                    <tr class="service">
                    <td style="padding-left:75px">
                    Others 
                    </td>
                    <td style="text-align:right" >$
                    ${othersCost.toFixed(2)}
                    </td>	
                    </tr>
                  `
                      : ""
                  }
              </tbody>
              <tfoot
                style="background-color: #cccc98; color: #000000"
                id="completepdfTableFooter"
              >
                <tr>
                  <td style="text-align: left"><b>Total</b></td>

                  <td style="text-align: right; color: #000000;"><b style='color: black;'>${total}</b></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    `;

    var opt = {
      margin: 5,

      filename: `${account}_${this.startOf}-${this.endOf}.pdf`,

      image: { type: "jpeg", quality: 1 },

      html2canvas: { dpi: 192, scale: 4, letterRendering: true, useCORS: true },

      pagebreak: {
        before: ".newPage",
        avoid: ["h2", "h3", "h4", "td", "tr", ".field"],
      },

      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  }

  getValue(value: string) {
    if (value == "projectTagName") {
      return this.projectTagNameEC2;
    }
    if (value == "mapMigrated") {
      return this.mapMigratedEC2;
    }
    if (value == "projectTagNameEBS") {
      return this.projectTagNameEBS;
    }
    if (value == "mapMigratedEBS") {
      return this.mapMigratedEBS;
    }
    return true;
  }

  async exportCSV(
    event?: any,
    account: string = this.accountId
  ): Promise<boolean> {
    return new Promise((resolve: any, reject: any) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      let ec2Index = 0;
      let total_element: any = document.createElement("span");
      let total = this.getDiscountedTotalRAW(account);
      total_element.innerHTML = total;
      var ondemandCost = parseFloat(
        this.response[account].onDemandFinalTotalCost
      );
      var spotCost = parseFloat(this.response[account].spotFinalTotalCost);
      var mRiCost = parseFloat(this.response[account].mRiTotalCost);
      var finalimriCost = parseFloat(this.response[account].mRiFinalTotalCost);
      var custRiCost = parseFloat(this.response[account].customerRITotalCost);
      var finalCustRiCost = parseFloat(
        this.response[account].customerRIFinalTotalCost
      );
      var ondemadisCost = parseFloat(this.response[account].disRiTotalCost);
      var finalDisCost = parseFloat(this.response[account].disRiFinalTotalCost);
      var minfyRiCost = parseFloat(this.response[account].mRiTotalCost);
      var finalMinfyRiCost = parseFloat(
        this.response[account].minfyRiFinalTotalCost
      );
      var ebsCost = parseFloat(this.response[account].ebsTotalCost);
      var othersCost = parseFloat(this.response[account].othersTotalCost);
      var totalec2Cost =
        ondemandCost +
        spotCost +
        finalMinfyRiCost +
        finalimriCost +
        finalCustRiCost +
        finalDisCost;

      let accountObj: any = JSON.parse(localStorage.getItem("accountIdData"))[
        "list"
      ].find((account_: any) => {
        return account_["accountId"] == account;
      });

      let dict: any = {
        sheetName: `${account}`,
        data: [
          [{ text: "Account Name:" }, { text: accountObj["accountName"] }],
          [
            {
              text: "Sl. No.",
            },
            {
              text: "Service Name",
            },
            {
              text: "Service Cost",
            },
          ],
        ],
      };

      this.response[account]["billDetails"]["items"][0]["services"]
        .sort((a: any, b: any) => {
          return a["service_name"] ==
            this.serviceNames[this.urlPrefix]["Amazon Elastic Compute Cloud"] ||
            a["service-name"] ==
              this.serviceNames[this.urlPrefix]["Amazon Elastic Compute Cloud"]
            ? 1
            : b["service_name"] ==
                this.serviceNames[this.urlPrefix][
                  "Amazon Elastic Compute Cloud"
                ] ||
              b["service-name"] ==
                this.serviceNames[this.urlPrefix][
                  "Amazon Elastic Compute Cloud"
                ]
            ? -1
            : 0;
        })
        .forEach((service: any, index: any) => {
          let data: any = [];
          let service_name = service.service_name || service["service-name"];
          let service_cost = Number(service["service-cost"]).toFixed(2);
          let servicename = null;
          if (
            service_name ===
              this.serviceNames[this.urlPrefix]["Amazon CloudFront"] &&
            this.servicesData[account]["cloudfront"]["discountedCost"]
          ) {
            servicename = "cloudfront";
          }
          if (
            service_name ===
              this.serviceNames[this.urlPrefix][
                "Amazon Simple Storage Service"
              ] &&
            this.servicesData[account]["s3"]["discountedCost"]
          ) {
            servicename = "s3";
          }
          if (
            service_name ===
              this.serviceNames[this.urlPrefix][
                "Amazon Elastic Compute Cloud"
              ] &&
            this.servicesData[account]["ec2"]["discountedCost"]
          ) {
            servicename = "ec2";
          }
          if (
            service_name ===
              this.serviceNames[this.urlPrefix][
                "Amazon Relational Database Service"
              ] &&
            this.servicesData[account]["rds"]["discountedCost"]
          ) {
            servicename = "rds";
          }

          if (
            service.service_name.includes(
              this.serviceNames[this.urlPrefix]["Amazon Elastic Compute Cloud"]
            )
          ) {
            ec2Index = index;
            servicename = "ec2";
          } else {
            ec2Index = null;
          }
          data.push({
            text: index + 1,
          });
          data.push({
            text: service_name,
          });
          if (servicename) {
            if (this.servicesData[account][servicename]["discountedCost"]) {
              data.push({
                text:
                  Number(
                    this.servicesData[account][servicename]["discountedCost"]
                  ) > Number(service_cost)
                    ? Number(service_cost)
                    : Number(
                        this.servicesData[account][servicename][
                          "discountedCost"
                        ]
                      ),
              });
            } else {
              data.push({
                text: Number(
                  this.servicesData[account][servicename]["service-cost"]
                ),
              });
            }
            if (servicename == "ec2") {
              dict.data.push(data);
              if (totalec2Cost > 0) {
                let data_: any = [];

                data_.push({
                  text: "",
                });
                data_.push({
                  text: "EC2",
                });
                data_.push({
                  text: Number(totalec2Cost.toFixed(2)),
                });
                dict.data.push(data_);
              }
              if (this.exportData["ec2"].length > 0) {
                dict.data.push([
                  { text: "" },
                  { text: "Sl. No." },
                  ...this.ec2Headers
                    .filter((h: any) => {
                      return h.export;
                    })
                    .map((h: any) => {
                      return { text: h["name"] };
                    }),
                ]);

                this.exportData["ec2"].map((ec2: any, index: any) => {
                  dict.data.push([
                    { text: "" },
                    { text: index + 1 },
                    ...this.ec2Headers
                      .filter((h: any) => {
                        return h.export;
                      })
                      .map((h: any) => {
                        let element: any = document.createElement("div");
                        if (h.alter) {
                          element.innerHTML = h.alter(ec2, h.id) || "-";

                          return {
                            text: h.alter(ec2, h.id)
                              ? isNaN(element.innerText)
                                ? element.innerText
                                : Number(element.innerText)
                              : "-",
                          };
                        }
                        element.innerHTML = ec2[h["id"]] || "-";
                        return {
                          text: element.innerText
                            ? isNaN(element.innerText)
                              ? element.innerText
                              : Number(element.innerText)
                            : "-",
                        };
                      }),
                  ]);
                });
              }
              if (ebsCost > 0) {
                let data_: any = [];

                data_.push({
                  text: "",
                });
                data_.push({
                  text: "EBS",
                });
                data_.push({
                  text: Number(ebsCost.toFixed(2)),
                });
                dict.data.push(data_);
              }
              if (this.exportData["ebs"].length > 0) {
                dict.data.push([
                  { text: "" },
                  { text: "Sl. No." },
                  ...this.ebsHeaders
                    .filter((h: any) => {
                      return h.export;
                    })
                    .map((h: any) => {
                      return { text: h["name"] };
                    }),
                ]);
                this.exportData["ebs"].map((ec2: any, index: any) => {
                  dict.data.push([
                    { text: "" },
                    { text: index + 1 },
                    ...this.ebsHeaders
                      .filter((h: any) => {
                        return h.export;
                      })
                      .map((h: any) => {
                        let element: any = document.createElement("div");
                        if (h.alter) {
                          element.innerHTML = h.alter(ec2, h.id) || "-";

                          return {
                            text: h.alter(ec2, h.id)
                              ? isNaN(element.innerText)
                                ? element.innerText
                                : Number(element.innerText)
                              : "-",
                          };
                        }
                        element.innerHTML = ec2[h["id"]] || "-";
                        return {
                          text: element.innerText
                            ? isNaN(element.innerText)
                              ? element.innerText
                              : Number(element.innerText)
                            : "-",
                        };
                      }),
                  ]);
                });
              }
              if (othersCost > 0) {
                let data_: any = [];

                data_.push({
                  text: "",
                });
                data_.push({
                  text: "Others",
                });
                data_.push({
                  text: Number(othersCost.toFixed(2)),
                });
                dict.data.push(data_);
              }
              if (this.exportData["others"].length > 0) {
                dict.data.push([
                  { text: "" },
                  { text: "Sl. No." },
                  ...this.othersHeaders
                    .filter((h: any) => {
                      return h.export;
                    })
                    .map((h: any) => {
                      return { text: h["name"] };
                    }),
                ]);
                this.exportData["others"].map((ec2: any, index: any) => {
                  dict.data.push([
                    { text: "" },
                    { text: index + 1 },
                    ...this.othersHeaders
                      .filter((h: any) => {
                        return h.export;
                      })
                      .map((h: any) => {
                        let element: any = document.createElement("div");
                        if (h.alter) {
                          element.innerHTML = h.alter(ec2[h.id]) || "-";

                          return {
                            text: isNaN(element.innerText)
                              ? element.innerHTML
                              : Number(element.innerHTML),
                          };
                        }
                        element.innerHTML = ec2[h["id"]] || "-";
                        return {
                          text: element.innerText
                            ? isNaN(element.innerText)
                              ? element.innerText
                              : Number(element.innerText)
                            : "-",
                        };
                      }),
                  ]);
                });
              }
            } else {
              dict.data.push(data);
            }
          } else {
            data.push({
              text: Number(service_cost),
            });
            dict.data.push(data);
          }
        });

      dict.data.push([
        {
          text: "",
        },
      ]);

      dict.data.push([
        {
          text: "",
        },
        {
          text: "Total",
        },
        {
          text: total_element.innerText,
        },
      ]);

      var options = {
        fileName: `${account}_${this.startOf}-${this.endOf}`,
      };

      Jhxlsx.export([dict], options);
      resolve(true);
    });
  }

  async fetchMasterAccounts() {
    if (
      this.masterAccounts.length > 0 ||
      this.customerType == "mp" ||
      this.mid
    ) {
      if(this.government_client == this.masterAccounts){
        console.log("this.masterAccounts",this.masterAccounts);
        this.governmentData(null);
      }
      // else if(this.government_client !== this.masterAccounts){
        await this.loadSummaryData(
          this.customerType !== "mp"
            ? this.mid || $("#masterAccountSelect").val()
            : undefined
        );
      // }
      
      return;
    }

    this.masterAccounts = [];
    this.curMissing = false;
    this.notifier.loading(true);
    let data = {
      lid: [this.lid || this.accountId],
      userType: "C",
      action: "fetchMasterAccList",
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    if (this.customerType == "mp") {
      apiURL = `${APIService.API_ENDPOINTV3}/market/billing/subdetails`;
    }

    if (environment.envName == "Development" && this.urlPrefix == "admin") {
      apiURL = `${environment.apiURL}/${this.urlPrefix}/billing/ec2breakup`;
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.masterAccounts = result.masterAccList;
      setTimeout(async () => {
        $("#masterAccountSelect").selectpicker("refresh");
        $("#masterAccountSelect").val(this.masterAccounts[0]);
        $("#masterAccountSelect").selectpicker("refresh");
        if(this.government_client == this.masterAccounts){
          await this.governmentData(null);

        }
      //  else if(this.government_client !== this.masterAccounts){
          await this.loadSummaryData(this.masterAccounts[0]);
console.log("supportPlanDetails fetchmasteraccounts");
        // }
      }, 100);
    } else {
      this.summary = [];
      this.activeAccount = null;
      this.notifier.alert("Info", "", result.msg, "info", 5000);
      this.notifier.loading(false);
    }

    if (this.masterAccounts.length == 0) {
      this.curMissing = true;
    }
    this.notifier.loading(false);

    // this.curMissing = true;

  }

  response: any = {};

  async loadSummaryData(account: any = "", lid: any = null) {
    console.log('condition', this.government_client == this.masterAccounts);
    return new Promise(async (resolve: any, reject: any) => {
      this.notifier.loading(true);
      try {
        let selectedAccountData: any = await this.fetchSelectedAccountDetails();
        if (!selectedAccountData.error) {
          const accountIdList = lid || selectedAccountData.data;
          let lid_: any = lid || this.lid || this.accountId;
          if (!this.activeAccount || lid_ != this.activeAccount.lid) {
            this.servicesData[lid_] = {
              ec2: {
                "service-cost": 0,
                "service-name":
                  this.urlPrefix == "client"
                    ? "AWS Elastic Compute Cloud"
                    : "Elastic Compute Cloud",
                "service-credits": 0,
              },
              rds: {
                "service-cost": 0,
                "service-name":
                  this.urlPrefix == "client"
                    ? "AWS Relational Database Service"
                    : "Relational Database Service",
                "service-credits": 0,
              },
              cloudfront: {
                "service-cost": 0,
                "service-name":
                  this.urlPrefix == "client" ? "AWS Cloudfront" : "Cloudfront",
                "service-credits": 0,
              },
              s3: {
                "service-cost": 0,
                "service-name":
                  this.urlPrefix == "client"
                    ? "Amazon Simple Storage Service"
                    : "S3",
                "service-credits": 0,
              },
              others: {
                "service-cost": 0,
                "service-name": "Others",
                "service-credits": 0,
                services: [],
              },
            };
          }
          let monthYear = moment().startOf("month").format("MM-YYYY");

          let dRange = $(".datetimepicker").val();
          if (dRange) {
            dRange = dRange.toString().split("-");
            monthYear = dRange[0] + "-" + dRange[1];
          }
          this.dateRange = monthYear;

          let data = {
            masterAccId:
              account && account != "" && this.customerType != "mp"
                ? account
                : undefined,
            month_year: monthYear,
            lid:
              (account && account != "") || this.customerType == "mp"
                ? [lid_]
                : accountIdList,
            userType: this.customerType || "C",
            action:
              this.customerType === "mp" ? "mpbills" : "supportPlanDetails",
          };
          let header = {
            Authorization: localStorage.getItem("t"),
          };
          let apiURL = `${APIService.API_ENDPOINTV3}/${
            this.urlPrefix
          }/billing/${
            (account && account != "") || this.customerType == "mp"
              ? "getservicelevelbill"
              : "getbills"
          }`;

          if (this.customerType == "mp") {
            apiURL = `${APIService.API_ENDPOINTV3}/market/billing/summary`;
          }

          if (
            this.urlPrefix == "admin" &&
            environment.envName == "Development"
          ) {
            apiURL = `${environment.apiURL}/${this.urlPrefix}/billing/getservicelevelbill`;
          }

          let result = await this.apiServer.postDataPromis(
            apiURL,
            data,
            header
          );

          if (result.status == "1" || result.s == "1") {
            this.response[lid_] = result;
            result.billDetails = result.billDetails || { items: [] };
            result.billDetails.items = result.billDetails.items.map(
              (account_: any) => {
                account_["mid"] = account;
                account_["accname"] = JSON.parse(
                  localStorage.getItem("accountIdData")
                )["list"].find((acc: any) => {
                  return acc["accountId"] == lid_;
                })["accountName"];
                account_.services = account_.services.map((service: any) => {
                  if (service["service_cost"]) {
                    service["service-cost"] = service["service_cost"];
                    service["service-name"] = service["service_name"];
                  }
                  service["discountedCost"] =
                    service["mrr_service_cost"] || service["service-cost"];
                  if (this.urlPrefix == "admin") {
                    service["service-name"] = service["service-name"]
                      .replaceAll("Amazon", "")
                      .trim();
                    service["service-name"] = service["service-name"]
                      .replaceAll("AWS ", "")
                      .trim();
                  }
                  if (
                    service["mrr_service_cost"] == undefined ||
                    Number(service["service-cost"]) ==
                      Number(service["mrr_service_cost"])
                  ) {
                    service["html"] = `
                                            <span>${Number(
                                              service["service-cost"]
                                            ).toFixed(4)}</span>`;
                  } else {
                    service[
                      "html"
                    ] = `<span class="line_through">${service["service-cost"]}</span> <span>${service["mrr_service_cost"]}</span>`;
                  }
                  return service;
                });
                return account_;
              }
            );
            if (!lid) {
              this.summary = result.billDetails.items;
            }
            if (
              result.appliedSupportPlanInfoList &&
              result.appliedSupportPlanInfoList.length > 0
            ) {
              result.appliedSupportPlanInfoList.forEach((support: any) => {
                let service: any = result.billDetails.items[0]["services"].find(
                  (service: any) => {
                    return (
                      support["supportPlanType"]
                        .toLowerCase()
                        .indexOf(service["service-name"].toLowerCase()) > -1
                    );
                  }
                );
                service["service-cost"] = support["updatedRate"].toString();
                service["discountedCost"] = (
                  support["discountedSupportPlanRate"] || support["updatedRate"]
                ).toString();

                if (support["discountedSupportPlanRate"] == 0) {
                  service["html"] = `<span>${Number(
                    service["service-cost"]
                  ).toFixed(4)}</span>`;
                } else {
                  if (service["service-cost"] != service["discountedCost"]) {
                    service["html"] = `<span class='line_through'>${Number(
                      service["service-cost"]
                    ).toFixed(4)}</span><span>${Number(
                      service["discountedCost"]
                    ).toFixed(4)}</span>`;
                  } else {
                    service["html"] = `<span>${Number(
                      service["service-cost"]
                    ).toFixed(4)}</span>`;
                  }
                }
              });
            }
            if (
              this.response[lid_] &&
              this.response[lid_].billDetails.items.length > 0 &&
              this.activeAccount
            ) {
              await this.showData(
                this,
                this.response[lid_].billDetails.items[0],
                lid_
              );
              if (this.download == "pdf") {
                this.exportPDF(null, lid_);
              }
            }
          } else {
            if (!lid) {
              this.summary = [];
              this.activeAccount = null;
              this.notifier.alert("Info", "", result.msg, "info", 5000);
            }
          }
        } else {
          this.notifier.alert(
            "Error",
            "",
            selectedAccountData.msg,
            "error",
            5000
          );
        }
      } catch (err: any) {
        this.notifier.alert("Error", "", err, "error", 5000);
      }
      if (!this.download) {
        this.notifier.loading(false);
      }

      resolve(true);
    });
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem("accountIdData");
      let selectedAccountDetail: String[];
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.map((account: any) => {
          return account.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: "No Account is Selected" });
      }
    });
  }

  // downloadCSV() {
  //   const csvData = this.convertToCSV(this.government_data);
  //   const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  //   const link = document.createElement('a');
  //   const url = URL.createObjectURL(blob);

  //   link.setAttribute('href', url);
  //   link.setAttribute('download', 'table-data.csv');
  //   link.style.visibility = 'hidden';

  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }
  downloadCSV() {
    this.notifier.loading(true);
  
    let element = document.createElement("table");
    let total: number = 0;
  
    // Construct table headers and body
    element.innerHTML = `
      <thead>
        <tr>
          <th>Sl. No.</th>
          ${this.government_headers.map((header: any) => `
            <th>${header.name}</th>
          `).join('')}
          <th>Total Service Cost</th>
        </tr>
      </thead>
      <tbody>
        ${this.government_data.map((data: any, index: number) => {
          return `
          <tr>
            <td>${index + 1}</td>
            ${this.government_headers.map((header: any) => {
              const cellData = data[header.id];
              // If cellData is blank (null, undefined, or empty), display "-"
              return `
                <td>${cellData ? cellData : '-'}</td>
              `;
            }).join('')}
            <td>${data.totalServiceCost !== null && data.totalServiceCost !== undefined ? data.totalServiceCost.toFixed(4) : '-'}</td>
          </tr>
          `;
        }).join('')}
      </tbody>
    `;
  
    // Export the table as CSV
    window.exportToExcelElement(
      element,
      `${this.lid || this.accountId}`,
      "csv"
    );
    this.notifier.loading(false);
  }
  
  

  convertToCSV(data: any[]): string {
    // Extract headers
    const headers = this.government_headers.map((h:any) => h.name).join(',');
    
    // Extract rows
    const rows = data.map(row => {
      return this.government_headers.map((h:any) => row[h.id]).join(',');
    });

    return headers + '\n' + rows.join('\n');
  }

  goBack() {
    this.activeAccount = null;
    if (this.lid) {
      this.hideModel.emit(false);
    }
  }

  callFunction(name: any, id: any) {
    this.funRef[name](this, id, this.lid || this.accountId);
  }

  getTotalRAW(account: any) {
    let raw_html = "";
    if (this.diffDays > 0) {
      let totalCost =
        this.response[account] &&
        !isNaN(this.response[account].finalODTotalCost) &&
        Number(this.response[account].finalODTotalCost).toFixed(2);
      raw_html = totalCost;
    } else {
      let totalCost =
        Number(this.servicesData[account]["ec2"]["service-cost"]) +
        Number(this.servicesData[account]["rds"]["service-cost"]) +
        Number(this.servicesData[account]["cloudfront"]["service-cost"]) +
        Number(this.servicesData[account]["s3"]["service-cost"]) +
        Number(this.servicesData[account]["others"]["service-cost"]);
      raw_html = `${totalCost}`;
    }

    return raw_html;
  }

  getDiscountedTotalRAW(account: any) {
    let raw_html = "";
    if (this.diffDays > 0) {
      let discountedCost = isNaN(this.response[account]["finalTotalCost"])
        ? "0"
        : this.response[account]["finalTotalCost"].toFixed(2);
      raw_html = discountedCost;
    } else {
      let totalCost =
        Number(this.servicesData[account]["ec2"]["service-cost"]) +
        Number(this.servicesData[account]["rds"]["service-cost"]) +
        Number(this.servicesData[account]["cloudfront"]["service-cost"]) +
        Number(this.servicesData[account]["s3"]["service-cost"]) +
        Number(this.servicesData[account]["others"]["service-cost"]);
      raw_html = `${totalCost}`;
    }

    if (Number(raw_html) < 0) {
      raw_html = `${this.activeAccount.totalCost.toFixed(2) || 0}`;
    }
    return raw_html;
  }

  getPDFTotal(account: any) {
    let mainTotal = (
      Number(this.servicesData[account]["ec2"]["service-cost"]) +
      Number(this.servicesData[account]["rds"]["service-cost"]) +
      Number(this.servicesData[account]["cloudfront"]["service-cost"]) +
      Number(this.servicesData[account]["s3"]["service-cost"]) +
      Number(this.servicesData[account]["others"]["service-cost"])
    ).toFixed(2);
    if (this.diffDays > 0) {
      let totalCost =
        this.response[account] &&
        !isNaN(this.response[account].finalODTotalCost) &&
        Number(this.response[account].finalODTotalCost);
      let discountedCost = isNaN(this.response[account]["finalTotalCost"])
        ? "-"
        : this.response[account]["finalTotalCost"].toFixed(2);
      if (totalCost.toFixed(2) == discountedCost) {
        return `<span><strong style='color: black;'>$${
          totalCost > 0
            ? totalCost.toFixed(2)
            : this.getDiscountedTotalRAW(account)
        }</strong></span>`;
      } else {
        return `<span style='text-decoration: line-through; color: black;'>$${totalCost.toFixed(
          2
        )}</span> <span><strong style='color: black;'>$${discountedCost}</strong></span>`;
      }
    } else {
      return `<span><strong style='color: black;'>$${mainTotal}</strong></span>`;
    }
  }

  getTotal(account: any) {
    let mainTotal = (
      Number(this.servicesData[account]["ec2"]["service-cost"]) +
      Number(this.servicesData[account]["rds"]["service-cost"]) +
      Number(this.servicesData[account]["cloudfront"]["service-cost"]) +
      Number(this.servicesData[account]["s3"]["service-cost"]) +
      Number(this.servicesData[account]["others"]["service-cost"])
    ).toFixed(2);
    if (this.diffDays > 0) {
      let totalCost =
        this.response[account] &&
        !isNaN(this.response[account].finalODTotalCost) &&
        Number(this.response[account].finalODTotalCost).toFixed(2);
      let discountedCost = isNaN(this.response[account]["finalTotalCost"])
        ? "-"
        : this.response[account]["finalTotalCost"].toFixed(2);
      if (totalCost == discountedCost) {
        return `<span class='bold_text'>$${totalCost}</span>`;
      } else {
        return `<span class='line_through'>$${
          this.response[account] &&
          !isNaN(this.response[account].finalODTotalCost) &&
          Number(this.response[account].finalODTotalCost).toFixed(2)
        }</span><span class='bold_text'>$${
          isNaN(this.response[account]["finalTotalCost"])
            ? "-"
            : this.response[account]["finalTotalCost"].toFixed(2)
        }</span>`;
      }
    } else {
      return `<span class='bold_text'>$${mainTotal}</span>`;
    }
  }

  async showData(ref: any, value: any, account: any) {
    await ref.filterData(JSON.stringify(value), account);
  }

  filterData(value: any, account: any) {
    let dt = JSON.parse(value);
    this.servicesData[account]["ec2"]["service-cost"] = 0;
    this.servicesData[account]["ec2"]["discountedCost"] = null;
    this.servicesData[account]["rds"]["service-cost"] = 0;
    this.servicesData[account]["rds"]["discountedCost"] = null;
    this.servicesData[account]["others"]["service-cost"] = 0;
    this.servicesData[account]["others"]["services"] = [];
    this.servicesData[account]["others"]["discountedCost"] = 0;
    this.servicesData[account]["cloudfront"]["service-cost"] = 0;
    this.servicesData[account]["cloudfront"]["discountedCost"] = null;
    this.servicesData[account]["s3"]["service-cost"] = 0;
    this.servicesData[account]["s3"]["discountedCost"] = null;
    dt.services.forEach((service: any, i: number) => {
      service["service-cost"] = Number(service["service-cost"]);
      if (
        service["service-name"] ===
        this.serviceNames[this.urlPrefix]["Amazon Relational Database Service"]
      ) {
        if (this.diffDays > 0) {
          service["discountedCost"] = !isNaN(
            this.response[account]["finalRDSUpdatedCost"]
          )
            ? this.response[account]["finalRDSUpdatedCost"]
              ? Number(
                  Number(this.response[account]["finalRDSUpdatedCost"]).toFixed(
                    2
                  )
                )
              : null
            : null;
          if (
            Number(service["discountedCost"]).toFixed(2) ==
            service["service-cost"].toFixed(2)
          ) {
            service["discountedCost"] = null;
          }
        }
        this.servicesData[account]["rds"] = service;
        return;
      }
      if (
        service["service-name"] ===
        this.serviceNames[this.urlPrefix]["Amazon CloudFront"]
      ) {
        if (this.diffDays > 0) {
          service["discountedCost"] =
            this.response[account]["finalCFRCTotalCost"] != undefined
              ? Number(this.response[account]["finalCFRCTotalCost"]).toFixed(4)
              : Number(this.response[account]["service-cost"]).toFixed(2);
          if (
            Number(service["discountedCost"]).toFixed(4) ==
            service["service-cost"].toFixed(4)
          ) {
            service["discountedCost"] = null;
          }
        }
        this.servicesData[account]["cloudfront"] = service;
        return;
      }
      if (
        service["service-name"] ===
        this.serviceNames[this.urlPrefix]["Amazon Simple Storage Service"]
      ) {
        if (this.diffDays > 0) {
          service["discountedCost"] = !isNaN(
            this.response[account]["finalS3TotalCost"]
          )
            ? this.response[account]["finalS3TotalCost"]
              ? Number(
                  Number(this.response[account]["finalS3TotalCost"]).toFixed(2)
                )
              : null
            : null;
          if (
            Number(service["discountedCost"]).toFixed(2) ==
            service["service-cost"].toFixed(2)
          ) {
            service["discountedCost"] = null;
          }
        }
        this.servicesData[account]["s3"] = service;
        return;
      }
      if (
        service["service-name"] ===
        this.serviceNames[this.urlPrefix]["Amazon Elastic Compute Cloud"]
      ) {
        if (this.diffDays > 0) {
          service["discountedCost"] = !isNaN(
            this.response[account]["finalEc2OdTotalCost"]
          )
            ? this.response[account]["finalEc2OdTotalCost"]
              ? Number(
                  Number(this.response[account]["finalEc2OdTotalCost"]).toFixed(
                    2
                  )
                )
              : null
            : null;
          if (
            Number(service["discountedCost"]).toFixed(2) ==
            service["service-cost"].toFixed(2)
          ) {
            service["discountedCost"] = null;
          }
        }
        this.servicesData[account]["ec2"] = service;
        return;
      }
      this.servicesData[account]["others"]["service-cost"] += Number(
        service["service-cost"]
      );

      if (service["discountedCost"] && Number(service["discountedCost"]) > 0) {
        this.servicesData[account]["others"]["discountedCost"] += Number(
          service["discountedCost"]
        );
      } else {
        this.servicesData[account]["others"]["discountedCost"] += Number(
          service["service-cost"]
        );
      }
      this.servicesData[account]["others"].services.push(service);
    });
    this.servicesData[account]["rds"]["service-cost"] =
      typeof this.servicesData[account]["rds"]["service-cost"] == "number"
        ? Number(
            Number(this.servicesData[account]["rds"]["service-cost"]).toFixed(2)
          )
        : Number(this.servicesData[account]["rds"]["service-cost"]);
    this.servicesData[account]["ec2"]["service-cost"] =
      typeof this.servicesData[account]["ec2"]["service-cost"] == "number"
        ? Number(
            Number(this.servicesData[account]["ec2"]["service-cost"]).toFixed(2)
          )
        : Number(this.servicesData[account]["ec2"]["service-cost"]);
    this.servicesData[account]["cloudfront"]["service-cost"] =
      typeof this.servicesData[account]["cloudfront"]["service-cost"] ==
      "number"
        ? Number(
            Number(
              this.servicesData[account]["cloudfront"]["service-cost"]
            ).toFixed(2)
          )
        : Number(this.servicesData[account]["cloudfront"]["service-cost"]);
    this.servicesData[account]["s3"]["service-cost"] =
      typeof this.servicesData[account]["s3"]["service-cost"] == "number"
        ? Number(
            Number(this.servicesData[account]["s3"]["service-cost"]).toFixed(2)
          )
        : Number(this.servicesData[account]["s3"]["service-cost"]);
    this.servicesData[account]["others"]["service-cost"] =
      typeof this.servicesData[account]["others"]["service-cost"] == "number"
        ? Number(
            Number(
              this.servicesData[account]["others"]["service-cost"]
            ).toFixed(2)
          )
        : Number(this.servicesData[account]["others"]["service-cost"]);

    if (
      this.servicesData[account]["others"]["discountedCost"] &&
      Number(this.servicesData[account]["others"]["discountedCost"]) > 0
    ) {
      this.servicesData[account]["others"]["discountedCost"] = Number(
        Number(this.servicesData[account]["others"]["discountedCost"])
      ).toFixed(2);
    } else {
      delete this.servicesData[account]["others"]["discountedCost"];
    }

    if ((account == this.lid || account == this.accountId) && !this.download) {
      this.activeAccount = dt;
    }
  }

  getOthers(i: any) {
    if (
      i["service-name"] ===
        this.serviceNames[this.urlPrefix][
          "Amazon Relational Database Service"
        ] ||
      i["service-name"] ===
        this.serviceNames[this.urlPrefix]["Amazon CloudFront"] ||
      i["service-name"] ===
        this.serviceNames[this.urlPrefix]["Amazon Simple Storage Service"] ||
      i["service-name"] ===
        this.serviceNames[this.urlPrefix]["Amazon Elastic Compute Cloud"]
    ) {
      return false;
    }
    return true;
  }

  showAnalytics(dt: any) {
    let accountData = { ...dt };
    delete accountData.services;
    this.analytics = {
      currentMonth: this.dateRange,
      account: accountData,
    };
  }

  hideAnalytics(event: any) {
    this.analytics = null;
  }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    let element = document.createElement("table");
    let total: number = 0;
    element.innerHTML = `
      <thead>
        <tr>
          <th>
            Sl. No.
          </th>
          <th>
            Service Name
          </th>
          <th>
            Service Cost
          </th>
        </tr>
      <thead>
      <tbody>
        ${this.servicesData[this.activeAccount.lid].others.services.map(
          (service: any, index: number) => {
            let value: number = Number(
              service["discountedCost"] || service["service-cost"] || 0
            );
            total += value;
            return `
            <tr>
              <td>${index + 1}</td>
              <td>${service["service-name"]}</td>
              <td>${value.toFixed(4)}</td>
            </tr>
          `;
          }
        )}
        <tr>
          <td></td>
          <td>Total</td>
          <td>${total.toFixed(4)}</td>
        </tr>
      </tbody>
    `;
    window.exportToExcelElement(
      element,
      `services-bills-${this.lid || this.accountId}`,
      "csv"
    );
  }

  preventDefault(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.downloadAll = !this.downloadAll;
  }

  async callAPIs(account: any, event: any) {
    return new Promise((resolve: any, reject: any) => {
      let promises: any = [];
      if (this.government_client === this.masterAccounts) {
        promises.push(
          this.governmentData(null)
        );
      }
    
      // if (this.government_client !== this.masterAccounts) {
        console.log('in callAPIs');
        promises.push(
          this.loadSummaryData($("#masterAccountSelect").val(), account)
        );
      // }
      promises.push(this.exportEC2(null, account));
      promises.push(this.exportEBS(null, account));
      promises.push(this.exportOthers(account));
      // if(this.government_client == this.masterAccounts){
      //   promises.push(this.governmentData(null));
      // }
      Promise.all(promises).then(async (values: any) => {
        if (!this.response[account]) {
          resolve(true);
          return;
        }
        await this.exportCSV(event, account);
        resolve(true);
        return;
      });
    });
  }

  calculateServiceCosts() {
    const productMap = new Map<string, { totalCost: number, firstIndex: number }>();
  
    // Iterate over government_data and group by productName
    this.government_data.forEach((item:any, index:any) => {
      // Ensure serviceCost is treated as a number
      const cost = parseFloat(item.unblendedCost) || 0;
  
      if (productMap.has(item.productName)) {
        // Add to the existing product's total cost
        const productInfo = productMap.get(item.productName);
        if (productInfo) {
          productInfo.totalCost += cost;
        }
      } else {
        // Initialize a new product in the map with the current service cost and index
        productMap.set(item.productName, { totalCost: cost, firstIndex: index });
      }
    });
  
    // Reset totalServiceCost for all rows to avoid appending
    this.government_data.forEach((item:any) => {
      item.totalServiceCost = null;
    });
  
    // Now assign the calculated totalCost only to the first occurrence of each productName
    productMap.forEach((value, productName) => {
      this.government_data[value.firstIndex].totalServiceCost = value.totalCost;
    });
  }
  
  
  

  async loadEC2Ddata(event: any) {
    this.notifier.loading(true);
    this.download = "csv";
    let lids = [];
    if (this.downloadAll) {
      let accounts = JSON.parse(localStorage.getItem("accountIdData"));
      lids = accounts.list.map((account: any) => {
        return account["accountId"];
      });
    } else {
      lids = [this.accountId];
    }
    for (let i = 0; i < lids.length; i++) {
      let account = lids[i];
      let result = await this.callAPIs(account, event);
    }
    this.notifier.loading(false);

    this.download = null;
    this.notifier.loading(false);

  }
  

  // CSV Export
  exportData: any = { ec2: [], ebs: [], others: [] };

  async exportEC2(lastEvalutedKey: any = null, account: any) {
    return new Promise(async (resolve: any, reject: any) => {
      if (!lastEvalutedKey) {
        this.exportData["ec2"] = [];
      }
      let data: any = {
        month_year: $(".datetimepicker").val(),
        lid: account,
        userType: this.customerType || "C",
        masterAccId:
          $("#masterAccountSelect") && this.customerType !== "mp"
            ? $("#masterAccountSelect").val()
            : undefined,
        more_instances: lastEvalutedKey ? lastEvalutedKey : undefined,
      };

      let header = {
        Authorization: localStorage.getItem("t"),
      };
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

      if (this.customerType == "mp") {
        apiURL = `${APIService.API_ENDPOINTV3}/market/billing/subdetails`;
      }

      if (environment.envName == "Development" && this.urlPrefix == "admin") {
        apiURL = `${environment.apiURL}/${this.urlPrefix}/billing/ec2breakup`;
      }

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if ((result.status == "1" || result.s == "1") && result.items) {
        this.exportData["ec2"] = [...this.exportData["ec2"], ...result.items];
      }
      if (result.more_instances) {
        await this.exportEC2(result.more_instances, account);
      }
      
      resolve(false);
    });
  }

  async governmentData(nextToken: any = null) {
    return new Promise(async (resolve: any, reject: any) => {
      if (!nextToken) {
        this.exportData["ebs"] = [];
      }
      let data: any = {
        month: $(".datetimepicker").val(),
        masterAccountId:
          $("#masterAccountSelect") && this.customerType !== "mp"
            ? $("#masterAccountSelect").val()
            : undefined,
        childAccountId: this.accountId,
        lek : nextToken
      };
      let header = {
        Authorization: localStorage.getItem("t"),
      };
      // let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;
      let apiURL =   `https://dev.api.swayam.cloud/v3/admin/billing/nhaclient`;
      // if (this.customerType == "mp") {
      //   apiURL = `${APIService.API_ENDPOINTV3}/market/billing/subdetails`;
      // }

      // if (environment.envName == "Development" && this.urlPrefix == "admin") {
      //   apiURL = `${environment.apiURL}/${this.urlPrefix}/billing/ec2breakup`;
      // }

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status == "1" || result.s == "1") {
        console.log("result", result);
        this.government_data = [...this.government_data, ...result.serviceModels];
        console.log("government_data",this.government_data);
      }
      else{
        this.government_data = result.serviceModels;
      }

      if (result.lek) {
        await this.governmentData(result.lek);
      }
      this.calculateServiceCosts();
      this.notifier.loading(false);
      console.log('in response', this.government_data);
      resolve(true);
    });
    
  }

  async exportEBS(nextToken: any = null, account: any) {
    return new Promise(async (resolve: any, reject: any) => {
      if (!nextToken) {
        this.exportData["ebs"] = [];
      }
      let data: any = {
        month_year: $(".datetimepicker").val(),
        lid: account,
        masterAccId:
          $("#masterAccountSelect") && this.customerType !== "mp"
            ? $("#masterAccountSelect").val()
            : undefined,
        userType: this.customerType || "C",
        more_instances: nextToken ? nextToken : undefined,
      };

      if (this.diffDays > 0) {
        data = {
          month_year: $(".datetimepicker").val(),
          lid: account,
          userType: this.customerType || "C",
          masterAccId:
            this.customerType !== "mp"
              ? $("#masterAccountSelect").val()
              : undefined,
          more_instances: nextToken ? nextToken : undefined,
          action: "fetchVolumes",
        };
      }

      let header = {
        Authorization: localStorage.getItem("t"),
      };
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/${
        this.diffDays > 0 ? "ec2breakup" : "volumedetails"
      }`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (
        (result.status == "1" || result.s == "1" || result.Items.length > 0) &&
        (result.Items || result.volumesList)
      ) {
        let dt: any = result.Items || result.volumesList;
        dt = dt.map((ebs: any) => {
          ebs.projectTagNameEBS = ebs.projectTagName;
          delete ebs.projectTagName;
          ebs.mapMigratedEBS = ebs.mapMigrated;
          delete ebs.mapMigrated;
          return ebs;
        });
        this.exportData["ebs"] = [...this.exportData["ebs"], ...dt];
      }
      if (result.more_instances) {
        await this.exportEBS(result.more_instances, account);
      }
      resolve(true);
    });
  }

  nextTokens: any = {};

  async exportOthers(account: any) {
    return new Promise(async (resolve: any, reject: any) => {
      if (!this.nextTokens["others"]) {
        this.exportData["others"] = [];
      }
      let data: any = {
        month_year: $(".datetimepicker").val(),
        lid: account,
        masterAccId:
          $("#masterAccountSelect") && this.customerType !== "mp"
            ? $("#masterAccountSelect").val()
            : undefined,
        more_instances: this.nextTokens["others"]
          ? this.nextTokens["others"]
          : undefined,
        action: "fetchTotalOtherDetails",
      };

      let header = {
        Authorization: localStorage.getItem("t"),
      };
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/${
        this.diffDays > 0 ? "ec2breakup" : "updatedusagedetails"
      }`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (
        ((result.status == "1" || result.s == "1") &&
          result.Items &&
          result.Items.length > 0) ||
        (result.othersDataList && result.othersDataList.length > 0)
      ) {
        this.exportData["others"] = [
          ...this.exportData["others"],
          ...(result.Items || result.othersDataList),
        ];
      }
      resolve(true);
    });
  }
}
