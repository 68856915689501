<div class="card card-border">
  <h5 class="card-header">{{ selectedCloudPartner == 'aws' ? 'EC2 Instances' : 'VMs' }}
  </h5>
  <div class="card-body card-border">
    <!--<h5 class="card-title">Case Details</h5>-->

    <div class="search-tags" [ngStyle]="{display: selectedCloudPartner == 'aws' ? 'flex': 'none'}">
      <div>Search By Tags</div>
      <div class="tag-list">
        <select class="form-control" [(ngModel)]="selectedTag" data-live-search="true" id="tagListID"
          (change)="changeTag()">
          <option *ngFor="let tag of tagList" value="{{tag}}">{{tag || '-'}}</option>
        </select>
      </div>
      <div class="value-list">
        <select class="form-control" [(ngModel)]="selectedValue" data-live-search="true" id="valueListID"
          (change)="changeValue()">
          <option *ngFor="let value of valueList" value="{{value}}">{{value || '-'}}</option>
        </select>
      </div>
      <div class="dropdown" [ngStyle]="{'display': getStartTriggerInstances().length > 0 ? 'block': 'none'}"
        *ngIf="writeAccess">
        <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
          id="instanceAction">
          Action
        </button>
        <div class="dropdown-menu" aria-labelledby="instanceAction">
          <a class="dropdown-item" href="#" (click)="triggerInstances($event, 'run')">Start</a>
          <a class="dropdown-item" href="#" (click)="triggerInstances($event, 'stop')">Stop</a>
        </div>
      </div>
    </div>
    <div class="overflow-auto">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th></th>
            <th>S.N.</th>
            <th *ngFor="let h of headers">
              {{h.name}}



              <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,instancesList)">

                <i class="fa fa-sort-amount-desc" aria-hi`dden="true" *ngIf="h.sort.direction == 'Des'"></i>
                <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
              </span>

              <span *ngIf="h.filter " class="inputseach">
                <input autocomplete="off" type="text" [(ngModel)]="h.filterText" />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
            <th *ngIf="checkForSwayamEMS()">EMS Status</th>
            <th *ngIf="selectedCloudPartner == 'aws'">Tags</th>
            <th *ngIf="writeAccess">Start / Stop</th>
          </tr>
        </thead>

        <tbody [ngStyle]="{'display': instancesList.length > 0 ? 'table-row-group' : 'none'}"
          *ngFor="let i of instancesList; let j = index">
          <tr *ngIf="notifier.filterTableColums(headers,i)">
            <td>
              <input autocomplete="off" type="checkbox" name="" id="" [(ngModel)]="i.actionStart">
            </td>
            <td>{{j+1}}</td>
            <td *ngFor="let h of headers">
              <span *ngIf="h.click" (click)="callFunction(h.click, i)" class="btn light">
                <span>
                  {{i[h.id] ||'-' }}
                </span>
              </span>
              <div *ngIf="!h.click">
                <div *ngIf="h.statusCheck">
                  <div class='status_div' [ngClass]="{'danger': i[h.id] == 'stopped' || i[h.id] == 'VM deallocated' , 'success': i[h.id] == 'running' || i[h.id] == 'VM running'}">
                    <div class='status_indicator'></div>
                    <div class='status_text'>{{i[h.id] || '-'}}</div>
                  </div>
                </div>
                <span *ngIf="!h.statusCheck">
                  {{i[h.id] || '-'}}
                </span>
              </div>
            </td>
            <td *ngIf="checkForSwayamEMS()">
              <div class="flex-start">
                <span class="status-indicator"
                  [ngClass]="{'red-fancy': i['ems_status'] == 0, 'green-fancy': i['ems_status'] == 1, 'grey-fancy': i['ems_status'] == -1 || i['ems_status'] == -2}">

                </span>
                <span class="status-indicator-text"
                  [ngClass]="{'red-text-fancy': i['ems_status'] == 0, 'green-text-fancy': i['ems_status'] == 1, 'grey-text-fancy': i['ems_status'] == -1 || i['ems_status'] == -2}">
                  <span *ngIf="i['ems_status'] == 1" class="color-inherit white-space-none">Active</span>
                  <span *ngIf="i['ems_status'] == 0" class="color-inherit white-space-none">Paused</span>
                  <span *ngIf="i['ems_status'] == -1" class="color-inherit white-space-none">Not Configured</span>
                  <span *ngIf="i['ems_status'] == -2" class="color-inherit white-space-none">EMS Not Configured</span>
                </span>
                <span *ngIf="i['ems_status'] != -2">
                  |
                </span>
                <span class="nav-link" (click)="setStatusChange(i)" *ngIf="i['ems_status'] == 0">
                  Activate
                </span>
                <span class="nav-link" (click)="setStatusChange(i)" *ngIf="i['ems_status'] == 1">
                  Pause
                </span>
                <span class="nav-link" (click)="createMonitorTrigger(i)" *ngIf="i['ems_status'] == -1">
                  Configure
                </span>
              </div>
            </td>
            <td *ngIf="selectedCloudPartner == 'aws'"><span class="btn light" (click)="showTags(i)">Tags</span> </td>
            <td *ngIf="writeAccess && i.instanceStatus == 'running' || i.state == 'VM running'">
              <span class="btn" (click)="singleStartStop($event, i, 'stop')">Stop</span>
            </td>
            <td *ngIf="writeAccess && i.instanceStatus != 'running' || i.state == 'VM stopped'">
              <span class="btn" (click)="singleStartStop($event, i, 'run')">Start</span>
            </td>
          </tr>
        </tbody>
        <tbody [ngStyle]="{'display': instancesList.length == 0 && !loading ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="headers.length + 5">
              <div class="no-instances-found">No Instances Found in the <span class="bold-text">{{ selectedCloudPartner == 'aws' ? regionId : resourceGroup }}</span>
                {{ selectedCloudPartner == 'aws' ? 'region' : 'resource group' }}. You can change the {{ selectedCloudPartner == 'aws' ? 'region' : 'resource group' }} on the top {{ selectedCloudPartner == 'aws' ? 'menu bar' : 'resource group tabs' }}</div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="loading">
          <tr>
            <td [attr.colspan]="headers.length + 5" class="center">
              <div>
                Looking for instances in <span class="bold-text">{{ selectedCloudPartner == 'aws' ? regionId : resourceGroup }}</span>...
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<tags-modal *ngIf="showTagsModal" modalTitle="Tags" instanceID={{showTagsModal.instanceId}} accountID={{accountId}}
  regionID={{regionId}} serviceName="ec2" (hideModal)="hideMdoal($event)"></tags-modal>
<ec2-details-view *ngIf="instance" [cloudPartner]="selectedCloudPartner" [instance]="instance" (hideModal)="hideDetails($event)"
  (addMonitorObj)="createMonitorTrigger($event)" (instanceStartStop)="instanceStartStop($event)"></ec2-details-view>
<instacne-start-stop *ngIf="command && writeAccess" [modalTitle]="modalTitle" [action]="action" [command]="command"
  [instances]="getStartTriggerInstances()" (hideModal)="hideModel()"></instacne-start-stop>


<site24x7-monitor-activate *ngIf="statusChange" (hideModel)="statusChange = null"
  [monitor]="statusChange"></site24x7-monitor-activate>

<app-create-monitor *ngIf="createMonitor" [accountId]="accountId" [instance]="createMonitor"
  (hideModel)="createMonitor = false"></app-create-monitor>