import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";

import * as moment from "moment";
import { environment } from "src/environments/environment";
declare let window: any;
declare let Jhxlsx: any;

@Component({
  selector: "cloudfront-billing",
  templateUrl: "cloudfront.billing.component.html",
  styleUrls: [
    "./../../billing.component.css",
    "./cloudfront.billing.component.css",
  ],
})
export class CloudFrontBillingComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input("activeAccount") activeAccount: any;
  @Input("monthYear") monthYear: any;
  @Input("diffDays") diffDays: any;

  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  customerType: string = localStorage.getItem("customerType");
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;

  regions: any = [];
  showConditionalElement = false;

  headers: any = [
    {
      id: "description",
      name: "Description",
    },
    {
      id: "totalUsage",
      name: "Usage",
    },
    {
      id: "totalCost",
      name: "Total Cost",
      alter: (value: any, param: string) => {
        return isNaN(value[param])
          ? "-"
          : Number(value[param]).toFixed(4) !=
              Number(value["totalUpdatedCost"]).toFixed(4) &&
            !isNaN(value["totalUpdatedCost"]) &&
            this.diffDays > 0
          ? `<span class='line_through'>${Number(value[param]).toFixed(
              4
            )}</span> <span>${Number(value["totalUpdatedCost"]).toFixed(
              4
            )}</span>`
          : `<span>${Number(value[param]).toFixed(4)}</span>`;
      },
    },
  ];
  exportHeaders: any = [
    {
      id: "region",
      name: "Region",
      export: true,
      display: true,
    },
    {
      id: "description",
      name: "Description",
      export: true,
      display: true,
    },
    {
      id: "totalUsage",
      name: "Usage",
      export: true,
      display: true,
    },
    {
      id: "totalCost",
      name: "Total Cost",
      export: true,
      conditionalShow: false,
      display: false,
      alter: (value: any, param: string) => {
        return isNaN(value[param]) ? "-" : Number(value[param]).toFixed(4);
      },
    },
    {
      id: "totalUpdatedCost",
      name: "Discount",
      export: true,
      display: false,
      conditionalShow: true,
      alter: (value: any, param: string) => {
        return isNaN(value[param])
          ? "-"
          : (Number(value["totalCost"]) - Number(value[param])).toFixed(4);
      },
    },
    {
      id: "totalUpdatedCost",
      name: "Final Cost",
      export: true,
      display: false,
      conditionalShow: true,
      alter: (value: any, param: string) => {
        return isNaN(value[param]) ? "-" : Number(value[param]).toFixed(4);
      },
    },
  ];

  accountId: any;
  regionId: any;
  currentMessage: any;
  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private zone: NgZone
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    this.customerType = localStorage.getItem("customerType");
    if (changes.activeAccount) {
      if (!this.monthYear || !this.activeAccount) {
        // this.notifier.alert('Error', '', 'Failed to Load Data', 'error', 5000);
      } else {
        if (this.customerType == "mp") {
          await this.checkForExistingClient();
        }
        this.loadData();
      }
    }
  }

  async ngOnInit() {}

  async checkForExistingClient() {
    let data: any = { a: "validate", accountId: this.activeAccount.lid };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/billinguser`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      if (result.existingCustomer) {
        this.customerType = "C";
      } else {
        this.customerType = "mp";
      }
    }
  }

  export(region: any) {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    let dict: any = {
      sheetName: `Cloudfront - ${this.activeAccount.lid}`,
      data: [
        [
          {
            text: "Sl. No.",
          },
          ...this.exportHeaders.map((h: any) => {
            return { text: h["name"] };
          }),
        ],
      ],
    };

    let total: number = 0;
    this.regions.forEach((region: any) => {
      region.data.forEach((instance: any) => {
        dict.data.push([
          { text: total + 1 },
          { text: region["name"] },
          { text: instance["description"] },
          { text: instance["totalUsage"] },
          { text: Number(instance["totalCost"] || 0) },
          {
            text: (function () {
              if (instance["totalUpdatedCost"] == "-") {
                return "-";
              } else {
                return (
                  Number(instance["totalCost"]) -
                  Number(instance["totalUpdatedCost"])
                );
              }
            })(),
          },
          {
            text: (function () {
              if (instance["totalUpdatedCost"] == "-") {
                return Number(instance["totalCost"]);
              } else {
                return Number(instance["totalUpdatedCost"]);
              }
            })(),
          },
        ]);
        total++;
      });
    });

    var options = {
      fileName: `Cloudfront - ${this.activeAccount.lid}`,
    };

    Jhxlsx.export([dict], options);
  }

  // async loadRegions() {
  //   this.notifier.loading(true);
  //   let data = {
  //     month_year: this.monthYear,
  //     lid: this.activeAccount.lid,
  //     masterAccId: $('#masterAccountSelect')
  //       ? $('#masterAccountSelect').val()
  //       : undefined,
  //     userType: 'C',
  //     action: 'fetchCloudFrontRegions',
  //   };
  //   let header = {
  //     Authorization: localStorage.getItem('t'),
  //   };
  //   let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

  // if(this.customerType == 'mp') {
  //   apiURL = `${APIService.API_ENDPOINTV3}/market/billing/subdetails`;
  // }

  //   let result = await this.apiServer.postDataPromis(apiURL, data, header);

  //   if (result.status == '1' || result.s == '1') {
  //     let dt = result.regionList;
  //     this.regions = dt.map((region: any) => {
  //       let data: any = [];
  //       return { name: region, expanded: false, data: data };
  //     });
  //     this.regions.length > 0 && this.loadData();
  //   } else {
  //     this.notifier.alert('Error', '', result.error, 'error', 5000);
  //   }
  //   this.notifier.loading(false);
  // }

  exportData: any = [];
  async loadData() {
    this.notifier.loading(true);
    let data = {
      month_year: this.monthYear,
      masterAccId: $("#masterAccountSelect")
        ? $("#masterAccountSelect").val()
        : undefined,
      lid: this.activeAccount.lid,
      userType: this.customerType || "C",
      action: "fetchCloudFrontData",
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    if (this.customerType == "mp") {
      apiURL = `${APIService.API_ENDPOINTV3}/market/billing/subdetails`;
    }

    if (environment.envName == "Development" && this.urlPrefix == "admin") {
      apiURL = `${environment.apiURL}/${this.urlPrefix}/billing/ec2breakup`;
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      let dt = result.cloudFrontDetailsList;
      this.exportData = result.cloudFrontDetailsList;
      this.regions = this.regions.map((region: any) => {
        region.data = [];
        return region;
      });
      if (dt) {
        dt.forEach((i: any) => {
          let region = this.regions.find((region: any) => {
            return region.name == i.region;
          });
          if (region) {
            region.data.push(i);
          } else {
            this.regions.push({
              data: [i],
              name: i.region,
            });
          }
        });
      }
    }

    this.notifier.loading(false);
  }

  showData(region: any) {
    region.expanded = !region.expanded;
  }

  ngOnDestroy(): void {}
}
