<div class="card">
  <h5 class="card-header">Automated Reports</h5>
  <div class="card-body">

    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link active" id="daily-reports-tab" data-bs-toggle="tab" data-bs-target="#daily-reports"
          type="button" role="tab" aria-controls="daily-reports" aria-selected="true"
          (click)="changeReportType('daily')">
          Daily
        </button>
        <button class="nav-link" id="weekly-reports-tab" data-bs-toggle="tab" data-bs-target="#weekly-reports"
          type="button" role="tab" aria-controls="weekly-reports" aria-selected="false"
          (click)="changeReportType('weekly')">
          Weekly
        </button>
      </div>
    </nav>

    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="daily-reports" role="tabpanel" aria-labelledby="daily-reports-tab">
        <div class="card border-none">
          <!--<h5 class="card-header"></h5>-->

          <div class="card-body">
            <!--<h5 class="card-title">Case Details</h5>-->
            <div class="card-dply">
              <div class="report-types-flex mb-10 card-postn">
                <table class="table table-docs">
                  <thead>
                    <tr>
                      <th>Sl. No.</th>
                      <th>Report Type</th>
                      <!-- <th>Description</th> -->
                      <th>Enable/Disable</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let report of reports; let i = index">
                      <td>{{i + 1}}</td>
                      <td>
                        <div>
                          {{reportsMapper[report.report_type]['name']}}
                        </div>
                        <div *ngIf="reportsMapper[report.report_type]['jira'] && !jira_access" class="light-text">
                          {{reportsMapper[report.report_type]['message']}}
                        </div>
                        <div *ngIf="reportsMapper[report.report_type]['site'] && !site_24x7_access" class="light-text">
                          {{reportsMapper[report.report_type]['message']}}
                        </div>
                      </td>
                      <!-- <td>{{reportsMapper[report.report_type]['name']}}</td> -->

                      <td>
                        <span
                          [ngClass]="{dim_button: reportsMapper[report.report_type]['jira'] && !jira_access || reportsMapper[report.report_type]['site'] && !site_24x7_access}">
                          <label class="switch" [ngStyle]="{display: report['loading'] ? 'none' : 'block'}">
                            <input autocomplete="off" type="checkbox" [(ngModel)]="report.selected"
                              [attr.disabled]="reportsMapper[report.report_type]['jira'] && !jira_access || reportsMapper[report.report_type]['site'] && !site_24x7_access ? true : null"
                              (change)="toggleReports(report)">
                            <span class="slider round"></span>
                          </label>
                          <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-16px padding20px"
                            [ngStyle]="{display: report['loading'] ? 'block' : 'none'}" />
                        </span>
                      </td>
                    </tr>
                    <tr *ngIf="loading">
                      <td colspan="4" class="txt-cntr">
                        <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="width-350px">
                <div class="label pad-styl">Email IDs</div>

                <div class="additional_contacts" [ngClass]="{disabled: selectedLength() <= 0}">
                  <div class="additional_contact width-auto" *ngFor="
                                        let contact of additionalContacts;
                                        let i = index;
                                        trackBy: trackByFn
                                      ">
                    <input autocomplete="off" type="text" name="contact_value" class="form-control"
                      *ngIf="additionalContacts.length - 3 < i" (keyup)="checkForLast(contact, i)"
                      [(ngModel)]="additionalContacts[i]" />
                    <input autocomplete="off" type="text" name="contact_value" class="form-control"
                      *ngIf="additionalContacts.length - 3 >= i" [(ngModel)]="additionalContacts[i]" />
                    <div>
                      <i class="fa fa-plus-circle pointer" aria-hidden="true" title="add"
                        *ngIf="additionalContacts.length == i + 1" (click)="addAdditionalContact()"></i>
                    </div>
                    <div>
                      <i class="fa fa-times-circle pointer" aria-hidden="true" title="remove"
                        *ngIf="additionalContacts.length > 1" (click)="deleteSelectedAdditionalContact(i)"></i>
                    </div>
                  </div>
                </div>
                <div class="error-text" *ngIf="totalAdditionalContacts() ==  0 && !loading && selectedLength() > 0">
                  At least one email id is required
                </div>
                <div *ngIf="!arrayEquals(prevContacts, additionalContacts)  && selectedLength() > 0 && !loading"
                  class="padding-5px">
                  <button type="button" class="btn btn-light btn-styl"
                    [ngClass]="{'click-disabled': !writeAccess || totalAdditionalContacts() == 0, dim_button: enabling || totalAdditionalContacts() == 0}"
                    [attr.disabled]="!writeAccess ? true : null" (click)="!enabling && toggleReports()">
                    <span [ngStyle]="{display: enabling ? 'block' : 'none'}">
                      <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-16px" />
                    </span>
                    <span class="spn-clr">
                      Update
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            *Daily Automated Reports are mailed at around 4 AM GMT
          </div>
          <div>
            *Reports are enabled specific to region. Please change if this
            is not a region of your preference.
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="weekly-reports" role="tabpanel" aria-labelledby="weekly-reports-tab">
        <div class="card border-none">
          <!--<h5 class="card-header"></h5>-->

          <div class="card-body">
            <!--<h5 class="card-title">Case Details</h5>-->
            <div class="card-dply">
              <div class="report-types-flex mb-10 card-postn">
                <table class="table table-docs">
                  <thead>
                    <tr>
                      <th>Sl. No.</th>
                      <th>Report Type</th>
                      <!-- <th>Description</th> -->
                      <th>Enable/Disable</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let report of reports; let i = index">
                      <td>{{i + 1}}</td>
                      <td>
                        <div>
                          {{reportsMapper[report.report_type]['name']}}
                        </div>
                        <div *ngIf="reportsMapper[report.report_type]['jira'] && !jira_access" class="light-text">
                          {{reportsMapper[report.report_type]['message']}}
                        </div>
                        <div *ngIf="reportsMapper[report.report_type]['site'] && !site_24x7_access" class="light-text">
                          {{reportsMapper[report.report_type]['message']}}
                        </div>
                      </td>
                      <!-- <td>{{reportsMapper[report.report_type]['name']}}</td> -->

                      <td>
                        <span
                          [ngClass]="{dim_button: reportsMapper[report.report_type]['jira'] && !jira_access || reportsMapper[report.report_type]['site'] && !site_24x7_access}">
                          <label class="switch" [ngStyle]="{display: report['loading'] ? 'none' : 'block'}">
                            <input autocomplete="off" type="checkbox" [(ngModel)]="report.selected"
                              [attr.disabled]="reportsMapper[report.report_type]['jira'] && !jira_access || reportsMapper[report.report_type]['site'] && !site_24x7_access ? true : null"
                              (change)="toggleReports(report)">
                            <span class="slider round"></span>
                          </label>
                          <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-16px padding20px"
                            [ngStyle]="{display: report['loading'] ? 'block' : 'none'}" />
                        </span>
                      </td>
                    </tr>
                    <tr *ngIf="loading">
                      <td colspan="4" class="txt-cntr">
                        <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="width-350px">
                <div class="label pad-styl">Email IDs</div>

                <div class="additional_contacts" [ngClass]="{disabled: selectedLength() <= 0}">
                  <div class="additional_contact width-auto" *ngFor="
                                                let contact of additionalContacts;
                                                let i = index;
                                                trackBy: trackByFn
                                              ">
                    <input autocomplete="off" type="text" name="contact_value" class="form-control"
                      *ngIf="additionalContacts.length - 3 < i" (keyup)="checkForLast(contact, i)"
                      [(ngModel)]="additionalContacts[i]" />
                    <input autocomplete="off" type="text" name="contact_value" class="form-control"
                      *ngIf="additionalContacts.length - 3 >= i" [(ngModel)]="additionalContacts[i]" />
                    <div>
                      <i class="fa fa-plus-circle pointer" aria-hidden="true" title="add"
                        *ngIf="additionalContacts.length == i + 1" (click)="addAdditionalContact()"></i>
                    </div>
                    <div>
                      <i class="fa fa-times-circle pointer" aria-hidden="true" title="remove"
                        *ngIf="additionalContacts.length > 1" (click)="deleteSelectedAdditionalContact(i)"></i>
                    </div>
                  </div>
                </div>
                <div class="error-text" *ngIf="totalAdditionalContacts() ==  0 && !loading && selectedLength() > 0">
                  At least one email id is required
                </div>
                <div *ngIf="!arrayEquals(prevContacts, additionalContacts)  && selectedLength() > 0 && !loading"
                  class="padding-5px">
                  <button type="button" class="btn btn-light btn-styl"
                    [ngClass]="{'click-disabled': !writeAccess || totalAdditionalContacts() == 0, dim_button: enabling || totalAdditionalContacts() == 0}"
                    [attr.disabled]="!writeAccess ? true : null" (click)="!enabling && toggleReports(null)">
                    <span [ngStyle]="{display: enabling ? 'block' : 'none'}">
                      <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-16px" />
                    </span>
                    <span class="spn-clr">
                      Update
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            *Weekly Automated Reports are mailed at around 4 AM GMT every Monday
          </div>
          <div>
            *Reports are enabled specific to region. Please change if this
            is not a region of your preference.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>