declare let window: any;

import { NgModule } from "@angular/core";
import { DataResolver } from "./_services/whitelabel.resolver";

import { RouterModule, Routes, TitleStrategy } from "@angular/router";
import { AuthGuard, canDeactivateGuard } from "./_helpers/auth.guard";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./login/register/register.component";
import { DashComponent } from "./dash/dash.component";
import { DashIndexComponent } from "./dash/index.component";

import { DashcComponent } from "./dashc/dash.component";
import { DashcIndexComponent } from "./dashc/index.component";

import { DashClientComponent } from "./client/dash.component";
import { DashClientIndexComponent } from "./client/index.component";

import { createTicketComponent } from "./views/ticket/create.component";
import { viewTicketComponent } from "./views/ticket/view/view.ticket.component";
import { knowledgebaseComponent } from "./views/knowledgebase/knowledgebase.component";

// compliance
import { ebsComponent } from "./views/compliance/ebs/ebs.component";
import { s3Component } from "./views/compliance/s3/s3.component";
import { cloudTrailComponent } from "./views/compliance/cloudtrail/cloudtrail.component";
import { configComponent } from "./views/compliance/config/config.component";
import { iamComponent } from "./views/compliance/iam/iam.component";
import { patchComponent } from "./views/compliance/patchreport/patch.component";

import { securityHubComponent } from "./views/compliance/security_hub/security.hub.component";
import { inspectorComponent } from "./views/compliance/inspector/inspector.component";
import { sgComponent } from "./views/compliance/security_groups/sg.component";
import { tagsComponent } from "./views/compliance/tags/tags.component";
import { advisorComponent } from "./views/compliance/advisor/advisor.component";
import { ec2CostOptimizationComponent } from "./views/costoptimization/ec2costoptimization/ec2costoptimization.component";
import { otherCostOptimizationComponent } from "./views/costoptimization/othercostoptimization/othercostopt.component";
import { floatingComponent } from "./views/costoptimization/floatingreport/floating.component";
import { ec2OperationsComponent } from "./views/operations/ec2/ec2.operations.component";
import { ForgotPasswordComponent } from "./login/forgot_password/forgot.component";

// SLA
import { CvsRComponent } from "./views/sla/createdvsresolved/createdvsresolved.component";
import { FirstResponseSLAComponent } from "./views/sla/firstresponsesla/firstresponsesla.component";
import { ResolutionSLA } from "./views/sla/resolutionsla/resolutionsla.component";
import { SLADashComponent } from "./views/sla-dash/sla-dash.component";

// Monitoring
import { MonitoringComponent } from "./views/monitoring/monitoring.component";
import { rdsOperationsComponent } from "./views/operations/rds/rds.component";
import { ebsOpsComponent } from "./views/operations/ebs/ebs.operations.component";
import { sgOperationsComponent } from "./views/operations/security_groups/sg.operations.component";
import { ITSIComponent } from "./views/operations/itsi/itsi.component";

// Anomalies
import { dashboardAnomaliesComponent } from "./views/anomalies/dashboard/dashboard.anomalies.component";
import { triggerHistoryAnomaliesComponent } from "./views/anomalies/trigger_history/trigger_history.anomalies.component";
import { exceptionsAnomaliesComponent } from "./views/anomalies/exceptions/exceptions.anomalies.component";

// Backup Report
import { backupReportComponent } from "./views/backupreport/backupreport.component";

// BOM
import { viewBOMComponent } from "./views/BOM/view/view.bom.component";
import { deltaReportBOMComponent } from "./views/BOM/deltareport/deltareport.bom.component";

// Reports
import { viewReportsComponent } from "./views/reports/view/view.reports.component";

// Billing
import { BillingComponent } from "./views/billing/billing.component";
import { CostAnamolyReportComponent } from "./views/beta/billing/components/cost-anamoly-report/cost-anamoly-report.component";

// Settings
import { auditSettingsComponent } from "./views/settings/audit/audit.settings.component";
import { ViewUserSettingsComponent } from "./views/settings/user/view/view.user.component";
import { ProfileSettingsComponent } from "./views/settings/user/profile/profile.settings.component";
import { InviteUserComponent } from "./views/settings/user/invite/invite.user.component";
import { TagsOperationsComponent } from "./views/operations/tags/tags.operations.component";
import { enableReportsComponent } from "./views/reports/enable/enable.reports.component";

// BOM
import { UploadBOMComponent } from "./views/BOM/upload/upload.bom.component";
import { ViewEC2GroupComponenet } from "./views/settings/ec2-group/view.ec2-group.component";
import { PoliciesComponenet } from "./views/settings/policies/manage.policies.component";

import { RICoverageCBSComponenet } from "./views/cbs/ricoverage/ricoverage.component";
import { RIPurchasedComponent } from "./views/cbs/ripurchased/ripurchased.component";
import { MinfyRIAccountComponent } from "./views/cbs/minfyriaccount/minfyriaccount.component";
import { OrganizationsCBSComponent } from "./views/cbs/organizations/organizations.component";
import { RIOrderComponent } from "./views/cbs/enableri/riorder.component";
import { ViewCFRCComponent } from "./views/cbs/cfrc/view/cfrc.view.component";
import { EnableCFRCComponent } from "./views/cbs/cfrc/enable/cfrc.enable.component";
import { viewSchedulertComponent } from "./viewsnew/scheduler/view.scheduler.component";

import { MasterSummaryComponent } from "./views/cbs/mastersummary/mastersummary.component";
import { EnableMasterComponent } from "./views/cbs/enablemaster/enablemaster.component";
import { DiscountComponent } from "./views/cbs/discount/discount.component";
import { RIOrderBillingComponent } from "./views/billing/ri/billing.ri.component";
import { supportPlanComponent } from "./views/cbs/supportplan/supportplan.component";
import { OnBoardedMasterComponent } from "./views/cbs/onboardedmaster/onboardedmaster.component";
import { AMIsComponent } from "./views/amis/amis.component";

import { RDSRIOrderComponent } from "./views/cbs/rdsenableri/rdsriorder.component";

import { BetaEc2CostOptimizationComponent } from "./views/beta/ec2costoptimization/ec2costoptimization.component";
import { BetaEBSCostOptimizationComponent } from "./views/beta/ebscostoptimization/ebscostoptimization.component";

import { BetaTagsCostOptimizationComponent } from "./views/beta/tagscostoptimization/tagscostoptimization.component";
import { BetaCreateTicketComponent } from "./views/beta/ticket/create.component";
import { BetaIAMUsersComponent } from "./views/beta/iamusers/iamusers.component";

import { FindingsComponent } from "./views/compliance/findings/findings.component";
import { SsbComponent } from "./views/ssb/ssb.component";
import { WarQuizComponent } from "./views/warquiz/warquiz.component";

import { CBSDashComponent } from "./cbsDash/dash.component";
import { UploadBOMv2Component } from "./views/BOMv2/upload/upload.bom.component";
import { BudgetBIllingComponent } from "./views/billing/budget/budget.component";
import { BetaDailyBillingComponent } from "./views/beta/billing/billing.component";
import { PricingComponent } from "./views/pricing/pricing.component";
import { TermsComponent } from "./views/terms/terms.component";
import { AccountsComponent } from "./views/accounts/accounts.component";
import { AwsComponent } from "./views/accounts/aws/aws.component";
import { BetaIAMAgeReportComponent } from "./views/beta/iam_age_report/iam_age_report.component";
import { BetaIAMGroupsComponent } from "./views/beta/iam_groups/iam_groups.component";
import { BetaS3AgeReportComponent } from "./views/beta/s3_ageing_report/s3_ageing.component";
import { ComparePlansComponent } from "./views/pricing/components/compare-plans/compare-plans.component";
import { AcmComponent } from "./views/operations/acm/acm.component";
import { RisavingsComponent } from "./views/costoptimization/risavings/risavings.component";
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { CreateCamsComponent } from "./views/cams/create-cams/create-cams.component";
import { ViewComponent } from "./views/cams/view/view.component";
import { DashboardReportsComponent } from "./views/reports/dashboard/dashboard.component";
import { TellMeComponent } from "./views/tell-me/tell-me.component";
import { ComplianceDashComponent } from "./views/compliance-dash/compliance-dash.component";
import { BillingDashboardComponent } from "./views/billing/dashboard/billingdashboard.component";
import { SwayamDocsComponent } from "./dash/components/swayam-docs/swayam-docs.component";
import { ListMonitorsComponent } from "./views/site24x7/components/list-monitors/list-monitors.component";
import { PrismaDashComponent } from "./views/prisma-dash/prisma-dash.component";
import { S3Component } from "./views/costoptimization/s3/s3.component";
import { ReservedInstancesComponent } from "./views/costoptimization/reserved-instances/reserved-instances.component";
import { CloudwatchCostControlsComponent } from "./views/costoptimization/cloudwatch-cost-controls/cloudwatch-cost-controls.component";
import { VmsComponent } from "./views/azure/vms/vms.component";
import { ManagedDisksComponent } from "./views/azure/managed-disks/managed-disks.component";
import { VirtualNetworksComponent } from "./views/azure/virtual-networks/virtual-networks.component";
import { DatabasesComponent } from "./views/azure/databases/databases.component";
import { AzureFunctionsComponent } from "./views/azure/azure-functions/azure-functions.component";
import { feedbackComponent } from "./viewsnew/feedback.component";
import { FtrComponent } from "./views/ftr/ftr.component";
import { RdsComponent } from "./views/costoptimization/rds/rds.component";
import { RBICompliance } from "./views/rbi-compliance/rbi-compliance.component";
import { DownloadDocComponent } from "./download-doc/download-doc.component";
import { TellMeHistoryComponent } from "./views/tell-me-history/tell-me-history.component";
import { GreenGameComponent } from "./views/greengame/greengame.component";
import { TellMeDocComponent } from "./tellme-doc/download-doc.component";
import { ChecksHomeComponent } from "./views/cost-optimization/home/home.component";
import { SecurityComponent } from "./views/security/security.component";
import { RdsriComponent } from "./views/costoptimization/rdsri/rdsri.component";
const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    component: LoginComponent,
    title: "Login",
    resolve: { data: DataResolver },
  },
  {
    path: "register",
    component: RegisterComponent,
    resolve: { data: DataResolver },
    title: "Register",
  },
  {
    path: "terms-and-conditions",
    component: TermsComponent,
    resolve: { data: DataResolver },
    title: "Terms and Conditions",
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    resolve: { data: DataResolver },
    title: "Forgot Password",
  },
  {
    path: "download-doc",
    component: DownloadDocComponent,
    resolve: { data: DataResolver },
    title: "Downloading",
  },
  {
    path: "tellme-doc",
    component: TellMeDocComponent,
    resolve: { data: DataResolver },
    title: "Downloading",
  },
  {
    path: "dash",
    component: DashComponent,
    resolve: { data: DataResolver },
    canActivate: [AuthGuard],
    children: [
      {
        path: "home",
        component: DashboardComponent,
        title: "Dashboard",
        canActivate: [AuthGuard],
        data: {
          permission: {
            menu_permission: "Dashboard:global",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS", "AZURE"],
          },
        },
      },
      {
        path: "swayam-docs",
        component: SwayamDocsComponent,
        resolve: { data: DataResolver },
        title: "Docs",
      },
      {
        path: "compliance/dash",
        component: ComplianceDashComponent,
        resolve: { data: DataResolver },
        title: "Dashboard",
        data: {
          permission: {
            menu_permission: "Dashboard:governance",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
          },
        },
      },
      {
        path: "prisma/dash",
        component: PrismaDashComponent,
        resolve: { data: DataResolver },
        title: "Prisma - Dashboard",
      },
      {
        path: "tell-me/alerts",
        component: TellMeHistoryComponent,
        title: "TellMe - Alerts",
        data: {
          permission: {
            menu_permission: "Alerts",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
          },
        },
      },
      {
        path: "sla/dashboard",
        component: SLADashComponent,
        resolve: { data: DataResolver },
        title: "SLA - Dashboard",
        data: {
          permission: {
            menu_permission: "SLAReport:dashboard",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
          },
        },
      },
      {
        path: "renewal",
        component: PricingComponent,
        resolve: { data: DataResolver },
        title: "Renewal",
      },
      { path: "", redirectTo: "index", pathMatch: "full" },
      { path: "index", component: DashIndexComponent, title: "Index" },
      {
        path: "ticket/list",
        component: viewTicketComponent,
        resolve: { data: DataResolver },
        title: "Tickets - List",
        data: {
          permission: {
            menu_permission: "Support:view tickets",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
          },
        },
      },
      {
        path: "ticket/create",
        component: BetaCreateTicketComponent,
        resolve: { data: DataResolver },
        title: "Tickets - Create",
        data: {
          permission: {
            menu_permission: "Support:create",
            write_access_required: true,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
          },
        },
      },
      {
        path: "knowledgebase",
        component: knowledgebaseComponent,
        data: {
          permission: {
            menu_permission: "Support:knowledge base",
            write_access_required: true,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: true,
          },
        },
      },
      {
        path: "compliance/ebs",
        component: ebsComponent,
        resolve: { data: DataResolver },
        title: "Compliance - EBS",
        data: {
          permission: {
            menu_permission: "Governance:ebs",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS", "AZURE"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "compliance/s3",
        component: s3Component,
        resolve: { data: DataResolver },
        title: "Compliance - S3",
        data: {
          permission: {
            menu_permission: "Security:s3",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "wafr",
        component: WarQuizComponent,
        resolve: { data: DataResolver },
        canDeactivate: [canDeactivateGuard],
        title: "WAFR Workloads",
        data: {
          permission: {
            menu_permission: "Compliance:wafr",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "rbi-compliance",
        component: RBICompliance,
        resolve: { data: DataResolver },
        title: "RBI Compliance",
        data: {
          permission: {
            menu_permission: "Compliance:rbi compliance",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "compliance/cloudtrail",
        component: cloudTrailComponent,
        resolve: { data: DataResolver },
        title: "Compliance - Cloudtrail",
        data: {
          permission: {
            menu_permission: "Operations:cloudtrail",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "compliance/config",
        component: configComponent,
        resolve: { data: DataResolver },
        title: "Compliance - Config",
        data: {
          permission: {
            menu_permission: "Monitoring:config",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "compliance/findings",
        component: FindingsComponent,
        resolve: { data: DataResolver },
        title: "Governance - Findings",
        data: {
          permission: {
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
            allowed_queries: {
              audit_type: [
                "iam",
                "ec2",
                "rds",
                "lambda",
                "kms",
                "cft",
                "dynamo",
                "cloudtrail",
                "cloudwatch",
                "cloudfront",
                "apigateway",
                "ses",
                "s3",
                "sg",
                "kube",
                "es",
                "sns",
                "redshift",
                "elb",
                "route53",
                "iam_audit",
                "vm_audit",
                "load_balancer_audit",
                "network_audit",
                "blob_audit",
                "app_service_audit",
                "aks_audit",
                "list_all_cosmodb_audit",
                "sql_audit",
                "synapse_audit",
                "dns_audit",
                "key_vault_audit",
                "monitor_audit",
                "log_alert_audit",
                "storage_audit",
              ],
            },
          },
        },
      },
      {
        path: "compliance/findings",
        component: FindingsComponent,
        resolve: { data: DataResolver },
        title: "Compliance - Findings",
        data: {
          permission: {
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "compliance/ssb",
        component: SsbComponent,
        resolve: { data: DataResolver },
        title: "Security Baseline",
        data: {
          permission: {
            menu_permission: "Compliance:ssb",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "green-game",
        component: GreenGameComponent,
        title: "Green Game",
      },
      {
        path: "compliance/patch",
        component: patchComponent,
        resolve: { data: DataResolver },
        title: "Compliance - Patch Report",
        data: {
          permission: {
            menu_permission: "Maintenance:patch report",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "compliance/securityhub",
        component: securityHubComponent,
        resolve: { data: DataResolver },
        title: "Compliance - Security Hub",
        data: {
          permission: {
            menu_permission: "Maintenance:securityhub",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "compliance/inspector",
        component: inspectorComponent,
        resolve: { data: DataResolver },
        title: "Compliance - Inspector",
        data: {
          permission: {
            menu_permission: "Maintenance:inspector",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      // {
      //   path: "compliance/sg",
      //   component: sgComponent,
      //   title: "Compliance - Security Groups",
      // },
      {
        path: "compliance/tags",
        component: tagsComponent,
        resolve: { data: DataResolver },
        title: "Compliance - Tags",
        data: {
          permission: {
            menu_permission: "Operations:tags",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "compliance/advisor",
        component: advisorComponent,
        resolve: { data: DataResolver },
        title: "Compliance - Trusted Advisor",
        data: {
          permission: {
            menu_permission: "Maintenance:trusted advisor",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "costopt/other",
        component: otherCostOptimizationComponent,
        resolve: { data: DataResolver },
        title: "Cost Contols - Others",
        data: {
          permission: {
            menu_permission: "Cost Controls:other",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "costopt/floating",
        component: floatingComponent,
        resolve: { data: DataResolver },
        title: "Cost Controls - AMIs and Snapshots",
        data: {
          permission: {
            menu_permission: "Cost Controls:amis",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "costcontrols/ec2-ri",
        component: RisavingsComponent,
        resolve: { data: DataResolver },
        title: "Cost Controls - EC2 RI",
        data: {
          permission: {
            menu_permission: "Cost Controls:ri recommendations",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "sla/createdvsresolved",
        component: CvsRComponent,
        resolve: { data: DataResolver },
        title: "SLA - Created vs Resolved",
        data: {
          permission: {
            menu_permission: "SLAReport:created vs resolved",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "sla/firstresponsesla",
        component: FirstResponseSLAComponent,
        resolve: { data: DataResolver },
        title: "SLA - First Response SLA",
        data: {
          permission: {
            menu_permission: "SLAReport:first response sla",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "sla/resolutionsla",
        component: ResolutionSLA,
        resolve: { data: DataResolver },
        title: "SLA - Resolution SLA",
        data: {
          permission: {
            menu_permission: "SLAReport:resolution sla",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "operations/ec2",
        component: ec2OperationsComponent,
        resolve: { data: DataResolver },
        title: "Operations - EC2",
        data: {
          permission: {
            menu_permission: "Opearations:ec2",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "operations/tags",
        component: TagsOperationsComponent,
        resolve: { data: DataResolver },
        title: "Operations - Tags",
        data: {
          permission: {
            menu_permission: "Operations:ec2",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS", "AZURE"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "operations/rds",
        component: rdsOperationsComponent,
        resolve: { data: DataResolver },
        title: "Operations - RDS",
        data: {
          permission: {
            menu_permission: "Operations:rds",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "operations/ebs",
        component: ebsOpsComponent,
        resolve: { data: DataResolver },
        title: "Operations - EBS",
        data: {
          permission: {
            menu_permission: "Operations:ebs",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "operations/sg",
        component: sgComponent,
        resolve: { data: DataResolver },
        title: "Operations - Security Groups",
        data: {
          permission: {
            menu_permission: "Security:security groups",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "operations/acm",
        component: AcmComponent,
        resolve: { data: DataResolver },
        title: "ACM",
        data: {
          permission: {
            menu_permission: "Operations:acm",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS", "AZURE"],
            admin_portal_only: false,
          },
        },
      },
      // {
      //   path: 'operations/itsi',
      //   component: DashClientIndexComponent,
      //   title: 'Operations - ITSI',
      // },
      {
        path: "monitoring",
        component: MonitoringComponent,
        resolve: { data: DataResolver },
        title: "Monitoring",
        data: {
          permission: {
            menu_permission: "Monitoring:ems dashboard",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "tell-me",
        component: TellMeComponent,
        resolve: { data: DataResolver },
        title: "Tell Me",
        data: {
          permission: {
            menu_permission: "Monitoring:tell me",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "anomalies/dashboard",
        component: dashboardAnomaliesComponent,
        resolve: { data: DataResolver },
        title: "Anomalies - Dashboard",
      },
      {
        path: "anomalies/history",
        component: triggerHistoryAnomaliesComponent,
        resolve: { data: DataResolver },
        title: "Anomalies - Trigger History",
      },
      {
        path: "anomalies/exceptions",
        component: exceptionsAnomaliesComponent,
        resolve: { data: DataResolver },
        title: "Anomalies - Exceptions",
      },
      // {
      //   path: "backupreport",
      //   component: backupReportComponent,
      //   title: "Backup Report",
      // },
      {
        path: "bom/view",
        canActivate: [AuthGuard],
        component: viewBOMComponent,
        resolve: { data: DataResolver },
        title: "BOM V1 - View",
      },
      {
        path: "bom/deltareport",
        canActivate: [AuthGuard],
        component: deltaReportBOMComponent,
        resolve: { data: DataResolver },
        title: "BOM v1 - Delta Report",
      },
      {
        path: "bom/upload",
        canActivate: [AuthGuard],
        component: UploadBOMComponent,
        resolve: { data: DataResolver },
        title: "BOM V1 - Upload",
      },
      {
        path: "reports/view",
        component: viewReportsComponent,
        resolve: { data: DataResolver },
        title: "Reports - View",
        data: {
          permission: {
            menu_permission: "Reports:view reports",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "reports/home",
        component: DashboardReportsComponent,
        resolve: { data: DataResolver },
        title: "Reports - Home",
      },
      {
        path: "reports/enable",
        component: enableReportsComponent,
        resolve: { data: DataResolver },
        title: "Reports - Enable",
        data: {
          permission: {
            menu_permission: "Reports:enable reports",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      // {
      //   path: "reports/tell-me",
      //   component: TellMeComponent,
      //   title: "Reports - Tell Me",
      // },
      {
        path: "billing",
        component: BillingComponent,
        title: "Billing",
        resolve: { data: DataResolver },
        data: {
          permission: {
            menu_permission: "Billing:monthly bill",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "settings/audittrail",
        component: auditSettingsComponent,
        resolve: { data: DataResolver },
        title: "Settings - Audit Trail",
        data: {
          permission: {
            menu_permission: "Settings:audit trail",
            allowed_cloud_partners: ["AWS", "AZURE"]
          },
        },
      },
      {
        path: "settings/users/view",
        component: ViewUserSettingsComponent,
        resolve: { data: DataResolver },
        title: "Settings - Users",
        data: {
          permission: {
            menu_permission: "Settings:users",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS", "AZURE"],
            admin_portal_only: false,
            required_user_type: ["admin", "userAdmin"],
          },
        },
      },
      {
        path: "settings/profile",
        component: ProfileSettingsComponent,
        resolve: { data: DataResolver },
        title: "Settings - Profile",
        data: {
          permission: {
            menu_permission: "Settings:user management",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS", "AZURE"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "settings/ec2group/view",
        component: ViewEC2GroupComponenet,
        resolve: { data: DataResolver },
        title: "Setting - EC2 Group",
      },
      {
        path: "settings/policies/view",
        component: PoliciesComponenet,
        resolve: { data: DataResolver },
        title: "Settings - Policies",
        data: {
          permission: {
            menu_permission: "Settings:policies",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS", "AZURE"],
            admin_portal_only: false,
            client_portal_only: true,
            required_user_type: ["admin", "userAdmin"],
          },
        },
      },
      {
        path: "billing/ri",
        component: RIOrderBillingComponent,
        resolve: { data: DataResolver },
        title: "Billing - RI",
        data: {
          permission: {
            menu_permission: "Billing:ri form",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: true,
          },
        },
      },
      {
        path: "billing/billing-daily",
        component: BetaDailyBillingComponent,
        resolve: { data: DataResolver },
        title: "Billing - Cost Anomaly",
        data: {
          permission: {
            menu_permission: "Cost Controls:billing daily",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "billing-daily",
        component: BetaDailyBillingComponent,
        resolve: { data: DataResolver },
        title: "Billing - Daily",
        data: {
          permission: {
            menu_permission: "Cost Controls:billing daily",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "billing/dashboard",
        component: BillingDashboardComponent,
        resolve: { data: DataResolver },
        title: "Billing Dashboard",
        data: {
          permission: {
            menu_permission: "Billing:dashboard",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "billing/billing-anomaly",
        component: CostAnamolyReportComponent,
        resolve: { data: DataResolver },
        title: "billing - anomaly",
        data: {
          permission: {
            menu_permission: "Cost Controls:billing anomaly",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      // {
      //   path: "billing/budgets",
      //   component: BudgetBIllingComponent,
      //   title: "Billing - Budgets",
      // },
      // { path: "amis", component: AMIsComponent, title: "AMIS" },
      {
        path: "costopt/ec2",
        component: BetaEc2CostOptimizationComponent,
        resolve: { data: DataResolver },
        title: "Cost Controls - EC2",
        data: {
          permission: {
            menu_permission: "Cost Controls:ec2",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "compliance/s3/age_report",
        component: BetaS3AgeReportComponent,
        resolve: { data: DataResolver },
        title: "Compliance - S3 Age Report",
      },
      {
        path: "compliance/iam/age_report",
        component: BetaIAMAgeReportComponent,
        resolve: { data: DataResolver },
        title: "Compliance - IAM Age Report",
        data: {
          permission: {
            menu_permission: "Security:iam access keys",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "compliance/iam/users",
        component: BetaIAMUsersComponent,
        resolve: { data: DataResolver },
        title: "Complaince - IAM Users",
        data: {
          permission: {
            menu_permission: "Security:iam users",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "costoptimization/ebs",
        component: BetaEBSCostOptimizationComponent,
        resolve: { data: DataResolver },
        title: "Cost Controls - EBS",
        data: {
          permission: {
            menu_permission: "Cost Controls:ebs",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            admin_portal_only: false,
          },
        },
      },
      {
        path: "cams-create",
        component: CreateCamsComponent,
        resolve: { data: DataResolver },
        title: "CAMS - Create",
      },
      {
        path: "cams-view",
        component: ViewComponent,
        resolve: { data: DataResolver },
        title: "CAMS - View",
      },
      {
        path: "accounts",
        component: AccountsComponent,
        resolve: { data: DataResolver },
        title: "Accounts",
        children: [
          {
            path: "aws",
            component: AwsComponent,
            resolve: { data: DataResolver },
            title: "Accounts - AWS",
            data: {
              permission: {
                write_access_required: false,
                marketplace_required: true,
                allowed_cloud_partners: ["AWS"],
                client_portal_only: true,
                required_user_type: ["admin"],
              },
            },
          },
          {
            path: "azure",
            component: AwsComponent,
            resolve: { data: DataResolver },
            title: "Accounts - Azure",
          },
        ],
      },
      {
        path: "beta",
        children: [
          {
            path: "costoptimization/tags",
            component: BetaTagsCostOptimizationComponent,
            resolve: { data: DataResolver },
            title: "Cost Controls - Tags",
          },
          {
            path: "costoptimization/tags",
            component: BetaTagsCostOptimizationComponent,
            resolve: { data: DataResolver },
            title: "Cost Controls - Tags",
          },

          {
            path: "ticket/create",
            component: BetaCreateTicketComponent,
            resolve: { data: DataResolver },
            title: "Create Ticket",
            data: {
              permission: {
                menu_permission: "Support:create ticket",
                write_access_required: true,
                marketplace_required: false,
                allowed_cloud_partners: ["AWS"],
              },
            },
          },

          {
            path: "iam/groups",
            component: BetaIAMGroupsComponent,
            resolve: { data: DataResolver },
            title: "IAM Groups",
          },
          {
            path: "bom/uploadv2",
            component: UploadBOMv2Component,
            resolve: { data: DataResolver },
            title: "BOM v2 - Upload",
          },
          {
            path: "automation/scheduler",
            component: viewSchedulertComponent,
            resolve: { data: DataResolver },
            title: "Scheduler",
          },
        ],
      },
      {
        path: "site24x7/monitors",
        component: ListMonitorsComponent,
        resolve: { data: DataResolver },
        title: "Site24x7 Monitors",
        data: {
          permission: {
            menu_permission: "Marketplace:site24X7",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            client_portal_only: false,
          },
        },
      },
      {
        path: "s3/:type",
        component: S3Component,
        resolve: { data: DataResolver },
        title: "S3 Cost Controls",
      },
      // {
      //   path: "cost-controls/rds",
      //   component: RdsComponent,
      //   resolve: { data: DataResolver },
      //   title: "RDS Cost Controls",
      //   data: {
      //     permission: {
      //       menu_permission: "Cost Controls:rds",
      //       write_access_required: false,
      //       marketplace_required: false,
      //       allowed_cloud_partners: ["AWS"],
      //       client_portal_only: false,
      //     },
      //   },
      // },
      {
        path: "cost-controls/reserved-instances",
        component: ReservedInstancesComponent,
        resolve: { data: DataResolver },
        title: "Reserved Instances",
        data: {
          permission: {
            menu_permission: "Cost Controls:reserved instances",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            client_portal_only: false,
          },
        },
      },
      {
        path: "cost-controls/rdsri",
        component: RdsriComponent,
        resolve: { data: DataResolver },
        title: "Reserved Instances-RDS",
        data: {
          permission: {
            menu_permission: "Cost Controls:RDS RI",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            client_portal_only: false,
          },
        },
      },

      {
        path: "azure",
        children: [
          {
            path: "networks",
            component: VirtualNetworksComponent,
            resolve: { data: DataResolver },
            title: "Azure - Managed Networks",
          },
          {
            path: "databases",
            component: DatabasesComponent,
            resolve: { data: DataResolver },
            title: "Azure - Databases",
          },
          {
            path: "functions",
            component: AzureFunctionsComponent,
            resolve: { data: DataResolver },
            title: "Azure - Functions",
          },
        ],
      },
      {
        path: "cost-controls/cloudwatch",
        component: CloudwatchCostControlsComponent,
        resolve: { data: DataResolver },
        title: "Cloudwatch Cost Controls",
        data: {
          permission: {
            menu_permission: "Cost Controls:cloudwatch",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            client_portal_only: false,
          },
        },
      },
      {
        path: "security/ftr",
        component: FtrComponent,
        resolve: { data: DataResolver },
        title: "Security - FTR",
        data: {
          permission: {
            menu_permission: "Compliance:ftr",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            client_portal_only: false,
          },
        },
      },
      {
        path: "security/aws-security-protocol",
        component: SecurityComponent,
        resolve: { data: DataResolver },
        title: "Cost Optimization",
        data: {
          permission: {
            menu_permission: "Compliance:ftr",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            client_portal_only: false,
          },
        },
      },
      {
        path: "security/advanced-security-protocol",
        component: ChecksHomeComponent,
        resolve: { data: DataResolver },
        title: "Cost Optimization",
        data: {
          permission: {
            menu_permission: "Compliance:ftr",
            write_access_required: false,
            marketplace_required: false,
            allowed_cloud_partners: ["AWS"],
            client_portal_only: false,
          },
        },
      },
      {
        path: "feedback",
        component: feedbackComponent,
        resolve: { data: DataResolver },
        title: "Feedback",
      },
    ],
  },
  {
    path: "dashc",
    component: DashcComponent,
    resolve: { data: DataResolver },
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "index", pathMatch: "full" },
      { path: "index", component: DashcIndexComponent },
    ],
  },
  {
    path: "admin",
    component: DashClientComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "index", pathMatch: "full" },
      { path: "index", component: DashClientIndexComponent },
      { path: "ticket/list", component: viewTicketComponent },
      { path: "ticket/create", component: createTicketComponent },
    ],
  },
];

const cbsAdminRoutes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "register", component: RegisterComponent },
  { path: "admin_login", component: LoginComponent },
  {
    path: "cbs",
    component: CBSDashComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "ricoverage", component: RICoverageCBSComponenet },
      { path: "ripurchased", component: RIPurchasedComponent },
      { path: "minfyri", component: MinfyRIAccountComponent },
      { path: "organizations", component: OrganizationsCBSComponent },
      { path: "riorder", component: RIOrderComponent },
      { path: "rds/riorder", component: RDSRIOrderComponent },
      { path: "cfrc/view", component: ViewCFRCComponent },
      { path: "cfrc/enable", component: EnableCFRCComponent },
      { path: "mastersummary", component: MasterSummaryComponent },
      { path: "masterenable", component: EnableMasterComponent },
      { path: "discount", component: DiscountComponent },
      { path: "enablesupportplan", component: supportPlanComponent },
      { path: "onboardedmaster", component: OnBoardedMasterComponent },
    ],
  },
];

const cbsRoutes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "register", component: RegisterComponent },
  { path: "admin_login", component: LoginComponent },
  {
    path: "cbs",
    component: CBSDashComponent,
    canActivate: [AuthGuard],
    children: [{ path: "mastersummary", component: MasterSummaryComponent }],
  },
];

let activeRoutes: Routes;
let current_location = window.location.host;

if (current_location === "cbs.swayam.cloud") {
  activeRoutes = cbsRoutes;
} else if (current_location === "cbsadmin.swayam.cloud") {
  activeRoutes = cbsAdminRoutes;
} else {
  activeRoutes = routes;
}

@NgModule({
  imports: [RouterModule.forRoot(activeRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
